@import 'sass-library/abstracts/variables';
/*
 * @param {Pixel} $size - Scrollbar width. Default: 4px
 * @param {Hex} $foreground-color - foreground scrollbar color. Default: 4px
 * @param {Hex} $background-color - Background scrollbar color. Default: transparent
 * @param {String} $circle - Scrollbar border-radius (only available in Google Chrome). Default: none
*/
@mixin custom-scrollbar(
  $size: 4px,
  $foreground-color: $rappi-scrollbar,
  $background-color: transparent,
  $circle: none
) {
  // For Google Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }
  &::-webkit-scrollbar-thumb {
    @if $circle == 'circle' {
      border-radius: 10px;
    } @else {
      border-radius: 0;
    }
    background: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer: non-standdard CSS properties
  scrollbar-face-color: $foreground-color;
  scrollbar-track-color: $background-color;

  // For Firefox and Firefox Android > v64.
  @if $size < 6px {
    scrollbar-width: thin;
  } @else {
    scrollbar-width: auto;
  }
  scrollbar-color: $foreground-color $background-color;
}
