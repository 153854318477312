.mat-tab-label {
  color: $primary-text;
  font-weight: 700;
  background-color: transparent;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-progress-bar-fill::after {
  background-color: $primary;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled) {
  background-color: rgba($primary, 0.3);
}
