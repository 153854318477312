.layout-content .title-bar-container .title-wrapper {
  .action,
  .action .report {
    .link,
    .download {
      &.action-wrapper {
        cursor: default;
      }

      .btn-wrapper {
        cursor: pointer;
      }
    }
  }

  .title-text {
    margin: 0 10px 0 0;
  }
}
