// ngx-one theme
@import '../../../node_modules/@rappi/common/assets/theme/_default-theme.scss';

// Core Variables and Mixins
@import 'scss/sass-library/abstracts/variables';

// Core Styles
@import 'scss/core';
@import 'scss/material';

// TODO: move to ngx-one or CDN theme
@import 'sass-library/components/dividers';
@import 'sass-library/components/title-bar';
@import 'sass-library/components/_buttons';
@import 'sass-library/components/_links';

// Utilities
@import 'utilities/_colors';

body {
  color: $primary-text;
  font-family: $font;
  width: 100%;

  input,
  button,
  .mat-tab-group,
  .mat-table,
  .mat-dialog-title,
  .mat-select,
  .mat-option,
  .mat-form-field-label,
  .mat-stepper-vertical,
  .mat-stepper-horizontal {
    font-family: $font;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  box-sizing: border-box;
  @include custom-scrollbar(6px, $gray-7, transparent, circle);
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/**
  REMOVE:
*/

.breadcrumb__splitter {
  display: flex;
}

app-status-dropdown mat-form-field,
mat-form-field.dynamic-select,
mat-form-field.app-search-box {
  .mat-form-field-underline {
    display: none;
  }
}
