.group-dayli-kpi {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: space-between;
  gap: 16px;
}

@media (min-width: $break-mobile) {
  .group-dayli-kpi {
    app-daily-kpi-card {
      .content-data {
        padding: 10px 23px;
      }

      .comparative-value {
        font-size: 24px;
      }
    }
  }
}

@media (min-width: $break-medium-desktop) {
  .group-dayli-kpi {
    app-daily-kpi-card {
      .content-data {
        padding: 14px 42px;
      }

      .comparative-value {
        font-size: 32px;
      }
    }
  }
}
