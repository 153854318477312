@import 'sass-library/abstracts/variables';

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border-radius: $border-radius-m;
  white-space: normal;
  border: 2px solid $gray-6;

  .mat-button-toggle + .mat-button-toggle {
    border-left: 2px solid $gray-6;
  }
}

.mat-button-toggle.mat-button-toggle-appearance-standard {
  white-space: normal;
  color: $secondary-text;

  .mat-button-toggle-label-content {
    line-height: 1.5;
    padding: 0.75rem;
  }
}

.mat-button-toggle-checked {
  background-color: $primary;

  &.mat-button-toggle-appearance-standard {
    color: white;
  }
}
