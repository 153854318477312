@import 'sass-library/abstracts/variables';

.brands-primary-button:not([disabled]) {
  background-color: $primary;
  color: white;
  border-radius: 8px;
  border: 1px solid $primary;
  font-weight: $heading-font-weight;
}

.brands-primary-button-underline:not([disabled]) {
  color: $primary;
  border: 1px solid $primary;
  border-radius: 8px;
  background-color: transparent;
}
