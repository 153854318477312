@import '../abstracts/variables';

.rp-input {
  background-color: $gray-4;
  border: 1px solid $gray-6;
  border-radius: $border-radius-l;
  padding: 5px 20px;
  outline: 0;
  box-shadow: none;
  font-family: $text-font;
  &::placeholder{
    color: $secondary-text-light;
  }
  &.rp-input-invalid {
    border-color: $warning;
  }
  &:focus {
    box-shadow: $shadow-focus;
    background-color: $white;
  }
}
