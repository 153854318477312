.rp-link {
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.rp-link-secondary {
  color: $secondary-text;
  text-decoration: underline;

  &:hover {
    color: $primary-text;
  }
}
