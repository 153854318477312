 /* Margin */
.m-5 { margin: 5px; }
.m-10 { margin: 10px; }
.m-15 { margin: 15px; }
.m-20 { margin: 20px; }
.m-25 { margin: 25px; }
.m-30 { margin: 30px; }

.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-25 { margin-top: 25px; }
.mt-30 { margin-top: 30px; }

.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }

.mr-5 { margin-right: 5px; }
.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }

.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }

/* Padding */
.p-5 { padding: 5px; }
.p-10 { padding: 10px; }
.p-15 { padding: 15px; }
.p-20 { padding: 20px; }
.p-25 { padding: 25px; }
.p-30 { padding: 30px; }

.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-20 { padding-top: 20px;}
.pt-25 { padding-top: 25px; }
.pt-30 { padding-top: 30px; }

.pb-5 { padding-bottom: 5px; }
.pb-10 { padding-bottom: 10px; }
.pb-15 { padding-bottom: 15px; }
.pb-20 { padding-bottom: 20px; }
.pb-25 { padding-bottom: 25px; }
.pb-30 { padding-bottom: 30px; }

.pr-5 { padding-right: 5px; }
.pr-10 { padding-right: 10px; }
.pr-15 { padding-right: 15px; }
.pr-20 { padding-right: 20px; }
.pr-25 { padding-right: 25px; }
.pr-30 { padding-right: 30px; }

.pl-5 { padding-left: 5px; }
.pl-10 { padding-left: 10px; }
.pl-15 { padding-left: 15px; }
.pl-20 { padding-left: 20px; }
.pl-25 { padding-left: 25px; }
.pl-30 { padding-left: 30px; }

/* Generics */
.pointer { cursor: pointer; }
.box-shadow-1 { box-shadow: 0 6px 10px 0 rgba(128, 98, 96, 0.16); }
.box-shadow-2 { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
.box-shadow-3 { box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
.box-shadow-4 { box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); }
.box-shadow-5 { box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); }
.box-shadow-6 { box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22); }
