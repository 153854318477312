.rtp-modal{
    overflow: auto; 
    display:block;
    .rtp-modal-shadow{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #3429437e;
        z-index: 100;
    }
    .rtp-modal-container{
        max-width: 410px;
        z-index: 101;
        -webkit-box-shadow: -1px 9px 14px -3px rgba(0,0,0,0.49);
        -moz-box-shadow: -1px 9px 14px -3px rgba(0,0,0,0.49);
        box-shadow: -1px 9px 14px -3px rgba(0,0,0,0.49);
        .modal-content{
            padding: 34px 30px;
            border:none;
            .modal-header{
                padding: 0;
                border-bottom: none;
                display: block;
                align-items: normal;
                .close-form{
                    position: absolute;
                    right: 22px;
                    top: 22px;
                    cursor: pointer;
                    &::before{
                        content: url('https://cdn.statically.io/gh/alonsovelez030/rtpfice-styles-library/1dcc8565/public/assets/icons/cerrar.svg')
                    }
                }
            }
            .modal-body{
                padding: 0px;
            }
        }
    }
}