@import '../abstracts/variables';

$primary-color: $primary;
$secondary-color: $gray-9;
$default-opacity: 0.5;
$default-margin-left: -1em;

@font-face {
  font-family: 'icomoon';
  src: url('#{$base-url-icons}/icomoon.eot'), url('#{$base-url-icons}/icomoon.eot?#iefix') format('embedded-opentype'),
    url('#{$base-url-icons}/icomoon.woff') format('woff'), url('#{$base-url-icons}/icomoon.ttf') format('truetype'),
    url('#{$base-url-icons}/icomoon.svg#svgFontName') format('svg');
  font-weight: initial;
  font-style: normal;
}

[class^='iconf-'],
[class*=' iconf-'] {
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-color;
}

.iconf-Logo-3x:before {
  content: '\ea21';
  color: $primary-color;
}

.iconf-gift:before {
  content: '\ea22';
  color: $secondary-color;
}

.iconf-local-bar:before {
  content: '\ea23';
  color: $secondary-color;
}

.iconf-local-grocery-store:before {
  content: '\ea24';
  color: $secondary-color;
}

.iconf-new-releases:before {
  content: '\ea25';
  color: $secondary-color;
}

.iconf-rappi-moustache:before {
  content: '\ea26';
  color: $secondary-color;
}

.iconf-restaurant-menu:before {
  content: '\ea27';
  color: $secondary-color;
}

.iconf-shopping-basket:before {
  content: '\ea28';
  color: $secondary-color;
}

.iconf-sync:before {
  content: '\ea29';
  color: $secondary-color;
}

.iconf-work:before {
  content: '\ea2a';
  color: $secondary-color;
}

.iconf-delete-outline:before {
  content: '\ea2b';
  color: $primary-color;
}

.iconf-account_state3x {
  &:before {
    content: '\e900';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e901';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-add_money3x {
  &:before {
    content: '\e902';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e903';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-add_phone3x {
  &:before {
    content: '\e904';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e905';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-add_profile3x {
  &:before {
    content: '\e906';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e907';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-add-card {
  &:before {
    content: '\e908';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e909';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-admin3x {
  &:before {
    content: '\e90a';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e90b';
    margin-left: $default-margin-left;
    color: $primary-color;
    opacity: $default-opacity;
  }
  .path3:before {
    content: '\e90c';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-arrrow_dopwn3x {
  &:before {
    content: '\e90d';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e90e';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-arrrow_left3x {
  &:before {
    content: '\e90f';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e910';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-arrrow_right3x {
  &:before {
    content: '\e911';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e912';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-arrrow_up3x {
  &:before {
    content: '\e913';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e914';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-ATM3x {
  &:before {
    content: '\e915';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e916';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-bank3x {
  &:before {
    content: '\e917';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e918';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-bars_code3x {
  &:before {
    content: '\e919';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e91a';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-bell {
  &:before {
    content: '\e91b';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e91c';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-bmo3x {
  &:before {
    content: '\e91d';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e91e';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-cake3x {
  &:before {
    content: '\e91f';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e920';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-calendar_in3x {
  &:before {
    content: '\e921';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e922';
    margin-left: $default-margin-left;
    color: $primary-color;
    opacity: $default-opacity;
  }
}

.iconf-calendar_out3x {
  &:before {
    content: '\e923';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e924';
    margin-left: $default-margin-left;
    color: $primary-color;
    opacity: $default-opacity;
  }
}

.iconf-calendar {
  &:before {
    content: '\e925';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e926';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-camera {
  &:before {
    content: '\e927';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e928';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-candado {
  &:before {
    content: '\e929';
    color: $primary-color;
  }
}

.iconf-basket {
  &:before {
    content: '\e92a';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e92b';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-card_reload3x {
  &:before {
    content: '\e92c';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e92d';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-cash {
  &:before {
    content: '\e92e';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e92f';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}

.iconf-cashback3x {
  &:before {
    content: '\e930';
    color: $primary-color;
    opacity: $default-opacity;
  }
  &:after {
    content: '\e931';
    margin-left: $default-margin-left;
    color: $primary-color;
  }
}
