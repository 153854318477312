@import '../abstracts/variables';

.rtp-tooltip{
    position: relative;
    span {
      padding: 8px 15px;
      display: inline-block;
      position: relative;
      border-radius: 3px;
      background-color: $primary-background;
      color: $white;
      font-family: $text-font;
      font-size: 13px;
    }

    span:before {
        content: '';
        position: absolute;
        top: 11px;
        left: -16px;
        border: 8px solid;
        border-color: transparent #56446F transparent transparent;
    }
}

