@keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(30px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes loading {
  0% {
  transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}
