$icomoon-font-family: 'rappi-one-icons' !default;
$icomoon-font-path: '../../fonts/icons' !default;

$one-icon-document-add: '\eb49';
$one-icon-document-clock: '\eb4a';
$one-icon-document-credit: '\eb4b';
$one-icon-download1: '\eb4c';
$one-icon-file-coin: '\eb4d';
$one-icon-file-fav: '\eb4e';
$one-icon-file-gif: '\eb4f';
$one-icon-folder: '\eb50';
$one-icon-government-v3: '\eb51';
$one-icon-graph-v3: '\eb52';
$one-icon-hand-fist: '\eb53';
$one-icon-hand-pointing: '\eb54';
$one-icon-hand: '\eb55';
$one-icon-house-v3: '\eb56';
$one-icon-image: '\eb57';
$one-icon-letters-ai: '\eb58';
$one-icon-link-v3: '\eb59';
$one-icon-login: '\eb5a';
$one-icon-message-v3: '\eb5b';
$one-icon-money-circle: '\eb5c';
$one-icon-money-lock: '\eb5d';
$one-icon-more-circle: '\eb5e';
$one-icon-numbers: '\eb5f';
$one-icon-open-eyes: '\eb60';
$one-icon-order: '\eb61';
$one-icon-padlock-open-v3: '\eb62';
$one-icon-paper-download: '\eb63';
$one-icon-paper-upload: '\eb64';
$one-icon-password-v3: '\eb65';
$one-icon-pen-ai: '\eb66';
$one-icon-pse: '\eb67';
$one-icon-rappicredits-v3: '\eb68';
$one-icon-save-v3: '\eb69';
$one-icon-scan: '\eb6a';
$one-icon-share-v3: '\eb6b';
$one-icon-shop-v3: '\eb6c';
$one-icon-social-tiktok: '\eb6d';
$one-icon-sort-by: '\eb6e';
$one-icon-square-download: '\eb6f';
$one-icon-thumbtack: '\eb70';
$one-icon-tools-v3: '\eb71';
$one-icon-transaction-report: '\eb72';
$one-icon-travel-plane: '\eb73';
$one-icon-turbo: '\eb74';
$one-icon-undo: '\eb75';
$one-icon-upload-v3: '\eb76';
$one-icon-user-pro-v3: '\eb77';
$one-icon-video-v3: '\eb78';
$one-icon-wifi-v3: '\eb79';
$one-icon-wrench: '\e900';
$one-icon-money-bag: '\e901';
$one-icon-users: '\e902';
$one-icon-food: '\e903';
$one-icon-phone: '\e904';
$one-icon-rappipay: '\e905';
$one-icon-credit-card: '\e906';
$one-icon-cash: '\e907';
$one-icon-calendar: '\e908';
$one-icon-female: '\e909';
$one-icon-male: '\e90a';
$one-icon-wallet: '\e90b';
$one-icon-torn-paper: '\e90c';
$one-icon-arrow-down: '\e90d';
$one-icon-briefcase: '\e90e';
$one-icon-flag: '\e90f';
$one-icon-flag-check: '\e910';
$one-icon-graph: '\e911';
$one-icon-target: '\e912';
$one-icon-pin: '\e913';
$one-icon-tag: '\e914';
$one-icon-tags: '\e915';
$one-icon-megaphone: '\e916';
$one-icon-paper-clock: '\e917';
$one-icon-clipboard-check: '\e918';
$one-icon-tooltip-check: '\e919';
$one-icon-user: '\e91a';
$one-icon-user-plus: '\e91b';
$one-icon-user-minus: '\e91c';
$one-icon-redo: '\e91d';
$one-icon-bifurcation: '\e91e';
$one-icon-repeat: '\e91f';
$one-icon-heart: '\e920';
$one-icon-heart-broken: '\e921';
$one-icon-info: '\e922';
$one-icon-check-circle: '\e923';
$one-icon-copy: '\e924';
$one-icon-caret-up: '\e925';
$one-icon-download: '\e926';
$one-icon-van: '\e927';
$one-icon-external: '\e928';
$one-icon-link: '\e929';
$one-icon-delete: '\e92a';
$one-icon-check: '\e92b';
$one-icon-wrong: '\e92c';
$one-icon-search: '\e92d';
$one-icon-bike: '\e92e';
$one-icon-trash: '\e92f';
$one-icon-cap: '\e930';
$one-icon-diagram: '\e931';
$one-icon-alert-rounded: '\e932';
$one-icon-pencil: '\e933';
$one-icon-shopping-cart: '\e934';
$one-icon-semi-scroll: '\e935';
$one-icon-sliders-v: '\e936';
$one-icon-menu-hamburger: '\e937';
$one-icon-user-circle-1: '\e938';
$one-icon-user-circle-2: '\e939';
$one-icon-arrow-left-1: '\e93a';
$one-icon-arrow-left-2: '\e93b';
$one-icon-building-1: '\e93c';
$one-icon-building-2: '\e93d';
$one-icon-award-1: '\e93e';
$one-icon-award-2: '\e93f';
$one-icon-user-plus-frame-1: '\e940';
$one-icon-user-plus-frame-2: '\e941';
$one-icon-keyboard-1: '\e942';
$one-icon-keyboard-2: '\e943';
$one-icon-user-frame-1: '\e944';
$one-icon-user-frame-2: '\e945';
$one-icon-coins-tag-1: '\e946';
$one-icon-coins-tag-2: '\e947';
$one-icon-phone-1: '\e948';
$one-icon-phone-2: '\e949';
$one-icon-video-plus-1: '\e94a';
$one-icon-video-plus-2: '\e94b';
$one-icon-badge-percent-1: '\e94c';
$one-icon-badge-percent-2: '\e94d';
$one-icon-present-1: '\e94e';
$one-icon-present-2: '\e94f';
$one-icon-magnifying-glass-1: '\e950';
$one-icon-magnifying-glass-2: '\e951';
$one-icon-plus-circle: '\e952';
$one-icon-key-1: '\e953';
$one-icon-key-2: '\e954';
$one-icon-ecommerce-1: '\e955';
$one-icon-ecommerce-2: '\e956';
$one-icon-dollar-sign: '\e957';
$one-icon-times: '\e958';
$one-icon-ban: '\e959';
$one-icon-chevron-left: '\e95a';
$one-icon-horizontal-link: '\e95b';
$one-icon-user-2: '\e95c';
$one-icon-lightning: '\e95d';
$one-icon-poster-dollar-1: '\e95e';
$one-icon-poster-dollar-2: '\e95f';
$one-icon-email-1: '\e960';
$one-icon-email-2: '\e961';
$one-icon-phone-plus-1: '\e962';
$one-icon-phone-plus-2: '\e963';
$one-icon-magic-stick-1: '\e964';
$one-icon-magic-stick-2: '\e965';
$one-icon-box-3d-1: '\e966';
$one-icon-box-3d-2: '\e967';
$one-icon-user-left-frame-1: '\e968';
$one-icon-user-left-frame-2: '\e969';
$one-icon-reload: '\e96a';
$one-icon-exit: '\e96b';
$one-icon-wheel: '\e96c';
$one-icon-question-circle: '\e96d';
$one-icon-chevron-down: '\e96e';
$one-icon-info-flat: '\e96f';
$one-icon-arrow-to-bottom: '\e970';
$one-icon-cancel-filled: '\e971';
$one-icon-language: '\e972';
$one-icon-tools: '\e973';
$one-icon-check-filled: '\e974';
$one-icon-mark-plus: '\e975';
$one-icon-short-arrow-down: '\e976';
$one-icon-chart-bars: '\e977';
$one-icon-three-dots: '\e978';
$one-icon-eye: '\e979';
$one-icon-user-tooltip: '\e97a';
$one-icon-keyboard: '\e97b';
$one-icon-present: '\e97c';
$one-icon-settings: '\e97d';
$one-icon-diagonal-tag: '\e97e';
$one-icon-user-circle: '\e97f';
$one-icon-user-plus-frame: '\e980';
$one-icon-star-solid: '\e981';
$one-icon-star-regular: '\e982';
$one-icon-star-half-alt: '\e983';
$one-icon-piled-up: '\e984';
$one-icon-clock: '\e985';
$one-icon-video: '\e986';
$one-icon-paper-check: '\e987';
$one-icon-compare-arrows: '\e988';
$one-icon-light: '\e989';
$one-icon-exclamation-circle: '\e98a';
$one-icon-plus: '\e98b';
$one-icon-paper-plan-fill: '\e98c';
$one-icon-trend-up-arrow: '\e98d';
$one-icon-reset-password: '\e98e';
$one-icon-key: '\e98f';
$one-icon-user-box: '\e990';
$one-icon-eye-solid: '\e991';
$one-icon-eye-solid-slash: '\e992';
$one-icon-offline-bolt: '\e993';
$one-icon-bookmark: '\e994';
$one-icon-oval-bar-chart: '\e995';
$one-icon-general-lifebuoy: '\e996';
$one-icon-outline-notification: '\e997';
$one-icon-outline-coupon: '\e998';
$one-icon-filled-favorite: '\e999';
$one-icon-general-add-circle-outline: '\e99a';
$one-icon-general-remove-circle-outline: '\e99b';
$one-icon-outline-flash: '\e99c';
$one-icon-outline-hotel: '\e99d';
$one-icon-outline-calendar: '\e99e';
$one-icon-outline-wallet: '\e99f';
$one-icon-general-timelapse: '\e9a0';
$one-icon-store: '\e9a1';
$one-icon-trash-outline: '\e9a2';
$one-icon-trash-1: '\e9a3';
$one-icon-filled-activity: '\e9a4';
$one-icon-outline-truck: '\e9a5';
$one-icon-outline-edit: '\e9a6';
$one-icon-add: '\e9a7';
$one-icon-headset: '\e9a8';
$one-icon-general-info-filled: '\e9a9';
$one-icon-add-money-v2: '\e9aa';
$one-icon-add-phone-v2: '\e9ab';
$one-icon-add-profile-v2: '\e9ac';
$one-icon-add-v2: '\e9ad';
$one-icon-admin-v2: '\e9ae';
$one-icon-arrow-down-v2: '\e9af';
$one-icon-arrow-left-v2: '\e9b0';
$one-icon-arrow-right-v2: '\e9b1';
$one-icon-arrow-up-v2: '\e9b2';
$one-icon-atm-v2: '\e9b3';
$one-icon-bad-face-v2: '\e9b4';
$one-icon-balance-v2: '\e9b5';
$one-icon-bank-v2: '\e9b6';
$one-icon-bars-code-v2: '\e9b7';
$one-icon-basket-v2: '\e9b8';
$one-icon-beauty-v2: '\e9b9';
$one-icon-bill-ok-v2: '\e9ba';
$one-icon-bowl-v2: '\e9bb';
$one-icon-box-v2: '\e9bc';
$one-icon-burger-v2: '\e9bd';
$one-icon-bus-v2: '\e9be';
$one-icon-cable-tv-v2: '\e9bf';
$one-icon-cake-v2: '\e9c0';
$one-icon-calendar-1-v2: '\e9c1';
$one-icon-calendar-in-v2: '\e9c2';
$one-icon-calendar-v2: '\e9c3';
$one-icon-camera-v2: '\e9c4';
$one-icon-canceled-order-v2: '\e9c5';
$one-icon-card-reload-v2: '\e9c6';
$one-icon-car-v2: '\e9c7';
$one-icon-cashback-pe-v2: '\e9c8';
$one-icon-cashback-v2: '\e9c9';
$one-icon-cemetery-v2: '\e9ca';
$one-icon-change-pin-v2: '\e9cb';
$one-icon-check-protection-v2: '\e9cc';
$one-icon-check-v2: '\e9cd';
$one-icon-chef-hat-v2: '\e9ce';
$one-icon-chronometer-v2: '\e9cf';
$one-icon-clock-v2: '\e9d0';
$one-icon-close-small-v2: '\e9d1';
$one-icon-close-v2: '\e9d2';
$one-icon-cloud-v2: '\e9d3';
$one-icon-code-v2: '\e9d4';
$one-icon-cook-v2: '\e9d5';
$one-icon-copy-v2: '\e9d6';
$one-icon-coupon-02-v2: '\e9d7';
$one-icon-coupon-v2: '\e9d8';
$one-icon-credit-card-v2: '\e9d9';
$one-icon-credit-v2: '\e9da';
$one-icon-diamond-v2: '\e9db';
$one-icon-divide-v2: '\e9dc';
$one-icon-download-box-v2: '\e9dd';
$one-icon-download-v2: '\e9de';
$one-icon-drop-ticket-v2: '\e9df';
$one-icon-ecomerce-v2: '\e9e0';
$one-icon-edit-v2: '\e9e1';
$one-icon-education-v2: '\e9e2';
$one-icon-email-v2: '\e9e3';
$one-icon-energy-v2: '\e9e4';
$one-icon-facebook-v2: '\e9e5';
$one-icon-face-id-v2: '\e9e6';
$one-icon-favorite-v2: '\e9e7';
$one-icon-filter-v2: '\e9e8';
$one-icon-finger-print-v2: '\e9e9';
$one-icon-flash-v2: '\e9ea';
$one-icon-flight-1-v2: '\e9eb';
$one-icon-flight-2-v2: '\e9ec';
$one-icon-flowers-v2: '\e9ed';
$one-icon-freeze-v2: '\e9ee';
$one-icon-gaming-v2: '\e9ef';
$one-icon-gas-v2: '\e9f0';
$one-icon-general-v2: '\e9f1';
$one-icon-gift-v2: '\e9f2';
$one-icon-golden-pass-v2: '\e9f3';
$one-icon-government-v2: '\e9f4';
$one-icon-graphic-bars-v2: '\e9f5';
$one-icon-green-pack-v2: '\e9f6';
$one-icon-grin-v2: '\e9f7';
$one-icon-groceries-v2: '\e9f8';
$one-icon-happy-face-v2: '\e9f9';
$one-icon-healt-v2: '\e9fa';
$one-icon-highway-v2: '\e9fb';
$one-icon-home-v2: '\e9fc';
$one-icon-hotel-v2: '\e9fd';
$one-icon-hot-v2: '\e9fe';
$one-icon-hourglass-v2: '\e9ff';
$one-icon-house-v2: '\ea00';
$one-icon-id-card-v2: '\ea01';
$one-icon-info-v2: '\ea02';
$one-icon-instagram-v2: '\ea03';
$one-icon-ipad-v2: '\ea04';
$one-icon-isapres-v2: '\ea05';
$one-icon-keyboard-v2: '\ea06';
$one-icon-key-v2: '\ea07';
$one-icon-lamp-v2: '\ea08';
$one-icon-lantern-v2: '\ea09';
$one-icon-lens-v2: '\ea0a';
$one-icon-levels-v2: '\ea0b';
$one-icon-life-buoy-v2: '\ea0c';
$one-icon-light-gas-v2: '\ea0d';
$one-icon-light-v2: '\ea0e';
$one-icon-linkedin-v2: '\ea0f';
$one-icon-location-v2: '\ea10';
$one-icon-log-out-v2: '\ea11';
$one-icon-magic-wand-v2: '\ea12';
$one-icon-message-v2: '\ea13';
$one-icon-missions-v2: '\ea14';
$one-icon-mobile-v2: '\ea15';
$one-icon-money-cash-v2: '\ea16';
$one-icon-money-ico-pe-v2: '\ea17';
$one-icon-money-ico-v2: '\ea18';
$one-icon-more-dots-v2: '\ea19';
$one-icon-more-services-1-v2: '\ea1a';
$one-icon-more-services-v2: '\ea1b';
$one-icon-mortgage-v2: '\ea1c';
$one-icon-motorcycle-v2: '\ea1d';
$one-icon-moustache-card-v2: '\ea1e';
$one-icon-navigation-v2: '\ea1f';
$one-icon-neutral-face-v2: '\ea20';
$one-icon-no-card-v2: '\ea21';
$one-icon-no-cash-v2: '\ea22';
$one-icon-no-money-v2: '\ea23';
$one-icon-notification-v2: '\ea24';
$one-icon-open-wallet-v2: '\ea25';
$one-icon-order-again-v2: '\ea26';
$one-icon-order-discount-v2: '\ea27';
$one-icon-outsourced-delivery-v2: '\ea28';
$one-icon-padlock-close-v2: '\ea29';
$one-icon-padlock-open-v2: '\ea2a';
$one-icon-passport-v2: '\ea2b';
$one-icon-person-card-v2: '\ea2c';
$one-icon-pets-v2: '\ea2d';
$one-icon-pharmacy-v2: '\ea2e';
$one-icon-phone-02-v2: '\ea2f';
$one-icon-phone-down-v2: '\ea30';
$one-icon-phone-signal-v2: '\ea31';
$one-icon-phone-v2: '\ea32';
$one-icon-place-holder-v2: '\ea33';
$one-icon-place-v2: '\ea34';
$one-icon-plane-v2: '\ea35';
$one-icon-points-v2: '\ea36';
$one-icon-prime-v2: '\ea37';
$one-icon-profile-circle-v2: '\ea38';
$one-icon-profile-v2: '\ea39';
$one-icon-promised-payment-v2: '\ea3a';
$one-icon-promoters-v2: '\ea3b';
$one-icon-promotion-v2: '\ea3c';
$one-icon-qr-rappi-v2: '\ea3d';
$one-icon-question-v2: '\ea3e';
$one-icon-rappi-card-v2: '\ea3f';
$one-icon-rappi-circle-v2: '\ea40';
$one-icon-rappicredits-txt-v2: '\ea41';
$one-icon-rappicredits-v2: '\ea42';
$one-icon-rappitendero-v2: '\ea43';
$one-icon-refund-product-v2: '\ea44';
$one-icon-refund-v2: '\ea45';
$one-icon-reload-v2: '\ea46';
$one-icon-restaurant-v2: '\ea47';
$one-icon-rewards-home-v2: '\ea48';
$one-icon-road-v2: '\ea49';
$one-icon-rocket-v2: '\ea4a';
$one-icon-rock-v2: '\ea4b';
$one-icon-sad-face-v2: '\ea4c';
$one-icon-satellital-tv-v2: '\ea4d';
$one-icon-search-tab-v2: '\ea4e';
$one-icon-settings-v2: '\ea4f';
$one-icon-sexshop-v2: '\ea50';
$one-icon-share1-v2: '\ea51';
$one-icon-share2-v2: '\ea52';
$one-icon-share-document-v2: '\ea53';
$one-icon-shopping-bag-v2: '\ea54';
$one-icon-shopping-v2: '\ea55';
$one-icon-shop-v2: '\ea56';
$one-icon-smile-face-v2: '\ea57';
$one-icon-star-outline-v2: '\ea58';
$one-icon-stationery-v2: '\ea59';
$one-icon-street-v2: '\ea5a';
$one-icon-strongbox-v2: '\ea5b';
$one-icon-support-2-v2: '\ea5c';
$one-icon-support-v2: '\ea5d';
$one-icon-suscriptions-v2: '\ea5e';
$one-icon-sync2-v2: '\ea5f';
$one-icon-sync-v2: '\ea60';
$one-icon-table-v2: '\ea61';
$one-icon-taxes-v2: '\ea62';
$one-icon-thunder-v2: '\ea63';
$one-icon-ticket-v2: '\ea64';
$one-icon-tools-v2: '\ea65';
$one-icon-top-up-pe-v2: '\ea66';
$one-icon-top-up-v2: '\ea67';
$one-icon-transfer-v2: '\ea68';
$one-icon-trash-v2: '\ea69';
$one-icon-trophy-v2: '\ea6a';
$one-icon-truck-v2: '\ea6b';
$one-icon-t-shirt-v2: '\ea6c';
$one-icon-tv-v2: '\ea6d';
$one-icon-two-street-v2: '\ea6e';
$one-icon-visibility-off-v2: '\ea6f';
$one-icon-visibility-v2: '\ea70';
$one-icon-wallet-v2: '\ea71';
$one-icon-warning2-v2: '\ea72';
$one-icon-warning-v2: '\ea73';
$one-icon-water-v2: '\ea74';
$one-icon-weighing-machine-v2: '\ea75';
$one-icon-whatsapp-v2: '\ea76';
$one-icon-wifi-v2: '\ea77';
$one-icon-withdraw-pe-v2: '\ea78';
$one-icon-withdraw-v2: '\ea79';
$one-icon-world-v2: '\ea7a';
$one-icon-acces-time-v2: '\ea7b';
$one-icon-account-state-v2: '\ea7c';
$one-icon-add-card-v2: '\ea7d';
$one-icon-add-location-v2: '\ea7e';
$one-icon-alarm-on-v2: '\ea7f';
$one-icon-alarm-v2: '\ea80';
$one-icon-arrow-back-ios-v2: '\ea81';
$one-icon-arrow-back-v2: '\ea82';
$one-icon-arrow-downward-v2: '\ea83';
$one-icon-arrow-drop-down-v2: '\ea84';
$one-icon-arrow-drop-up-v2: '\ea85';
$one-icon-arrow-forward-ios-v2: '\ea86';
$one-icon-arrow-forward-v2: '\ea87';
$one-icon-arrow-left-material-v2: '\ea88';
$one-icon-arrow-right-material-v2: '\ea89';
$one-icon-arrow-top-v2: '\ea8a';
$one-icon-arrow-upward-v2: '\ea8b';
$one-icon-assistant-v2: '\ea8c';
$one-icon-attach-file-v2: '\ea8d';
$one-icon-attachment-v2: '\ea8e';
$one-icon-attach-money-v2: '\ea8f';
$one-icon-autorenew-v2: '\ea90';
$one-icon-backspace-v2: '\ea91';
$one-icon-bar-chart-v2: '\ea92';
$one-icon-block-v2: '\ea93';
$one-icon-bookmark-border-v2: '\ea94';
$one-icon-bookmark-v2: '\ea95';
$one-icon-build-v2: '\ea96';
$one-icon-calendar-today-v2: '\ea97';
$one-icon-call-v2: '\ea98';
$one-icon-camera-alt-v2: '\ea99';
$one-icon-cancel-v2: '\ea9a';
$one-icon-chat-v2: '\ea9b';
$one-icon-check-circle-outline-v2: '\ea9c';
$one-icon-check-material-v2: '\ea9d';
$one-icon-cherck-circle-v2: '\ea9e';
$one-icon-chevron-left-v2: '\ea9f';
$one-icon-chevron-right-v2: '\eaa0';
$one-icon-clock-material-v2: '\eaa1';
$one-icon-close-material-v2: '\eaa2';
$one-icon-colapse-view-v2: '\eaa3';
$one-icon-compare-arrows-v2: '\eaa4';
$one-icon-computer-v2: '\eaa5';
$one-icon-credit-card-material-v2: '\eaa6';
$one-icon-delete-outline-v2: '\eaa7';
$one-icon-delete-v2: '\eaa8';
$one-icon-dialpad-v2: '\eaa9';
$one-icon-directions-bike-v2: '\eaaa';
$one-icon-directions-bus-v2: '\eaab';
$one-icon-directions-run-v2: '\eaac';
$one-icon-drive-eta-v2: '\eaad';
$one-icon-edit-material-v2: '\eaae';
$one-icon-email-material-v2: '\eaaf';
$one-icon-emoji-events-v2: '\eab0';
$one-icon-emoji-objects-v2: '\eab1';
$one-icon-error-outline-v2: '\eab2';
$one-icon-error-v2: '\eab3';
$one-icon-event-v2: '\eab4';
$one-icon-expand-less-v2: '\eab5';
$one-icon-expand-more-v2: '\eab6';
$one-icon-expand-view-v2: '\eab7';
$one-icon-explore-v2: '\eab8';
$one-icon-favorite-border-v2: '\eab9';
$one-icon-favorite-material-v2: '\eaba';
$one-icon-filter-material-v2: '\eabb';
$one-icon-fingerprint-v2: '\eabc';
$one-icon-flight-v2: '\eabd';
$one-icon-get-app-v2: '\eabe';
$one-icon-gift-material-v2: '\eabf';
$one-icon-gluten-v2: '\eac0';
$one-icon-headset-mic-v2: '\eac1';
$one-icon-help-outline-v2: '\eac2';
$one-icon-help-v2: '\eac3';
$one-icon-highlight-off-v2: '\eac4';
$one-icon-highlight-v2: '\eac5';
$one-icon-history-v2: '\eac6';
$one-icon-home-material-v2: '\eac7';
$one-icon-id-card-material-v2: '\eac8';
$one-icon-image-v2: '\eac9';
$one-icon-inbox-v2: '\eaca';
$one-icon-info-material-v2: '\eacb';
$one-icon-iwatch-v2: '\eacc';
$one-icon-keyboard-material-v2: '\eacd';
$one-icon-label-v2: '\eace';
$one-icon-language-v2: '\eacf';
$one-icon-lifebuoy-material-v2: '\ead0';
$one-icon-lifemiles-v2: '\ead1';
$one-icon-linkedin-v21: '\ead2';
$one-icon-link-v2: '\ead3';
$one-icon-local-activity-v2: '\ead4';
$one-icon-local-bar-v2: '\ead5';
$one-icon-local-florist-v2: '\ead6';
$one-icon-local-gas-station-v2: '\ead7';
$one-icon-local-grocery-store-v2: '\ead8';
$one-icon-local-hotel-v2: '\ead9';
$one-icon-local-mall-v2: '\eada';
$one-icon-local-offer-v2: '\eadb';
$one-icon-local-pizza-v2: '\eadc';
$one-icon-local-printshop-v2: '\eadd';
$one-icon-lock-close-material-v2: '\eade';
$one-icon-lock-open-material-v2: '\eadf';
$one-icon-loop-v2: '\eae0';
$one-icon-loyalty-v2: '\eae1';
$one-icon-map-v2: '\eae2';
$one-icon-medical-prescription-v2: '\eae3';
$one-icon-menu-book-v2: '\eae4';
$one-icon-menu-v2: '\eae5';
$one-icon-mic-none-v2: '\eae6';
$one-icon-mic-off-v2: '\eae7';
$one-icon-mic-v2: '\eae8';
$one-icon-monetization-v2: '\eae9';
$one-icon-money-circle-v2: '\eaea';
$one-icon-more-horiz-v2: '\eaeb';
$one-icon-more-vert-v2: '\eaec';
$one-icon-my-location-v2: '\eaed';
$one-icon-navigation-material-v2: '\eaee';
$one-icon-near-me-v2: '\eaef';
$one-icon-new-releases-v2: '\eaf0';
$one-icon-north-east-v2: '\eaf1';
$one-icon-notifications-active-v2: '\eaf2';
$one-icon-not-interested-v2: '\eaf3';
$one-icon-offline-bolt-v2: '\eaf4';
$one-icon-pause-circle-v2: '\eaf5';
$one-icon-people-v2: '\eaf6';
$one-icon-person-outline-v2: '\eaf7';
$one-icon-person-pin-v2: '\eaf8';
$one-icon-person-v2: '\eaf9';
$one-icon-phone-iphone-v2: '\eafa';
$one-icon-pin-v2: '\eafb';
$one-icon-play-circle-v2: '\eafc';
$one-icon-promo-material-v2: '\eafd';
$one-icon-qr-rappi-material-v2: '\eafe';
$one-icon-query-builder-v2: '\eaff';
$one-icon-rappi-moustache-v2: '\eb00';
$one-icon-refresh-v2: '\eb01';
$one-icon-remove-circle-outline-v2: '\eb02';
$one-icon-remove-circle-v2: '\eb03';
$one-icon-remove-v2: '\eb04';
$one-icon-report-v2: '\eb05';
$one-icon-restaurant-menu-v2: '\eb06';
$one-icon-room-service-v2: '\eb07';
$one-icon-room-v2: '\eb08';
$one-icon-save-v2: '\eb09';
$one-icon-search-v2: '\eb0a';
$one-icon-send-v2: '\eb0b';
$one-icon-sentimental-satisfied-v2: '\eb0c';
$one-icon-sentiment-dissatisfied-v2: '\eb0d';
$one-icon-sentiment-very-dissatisfied-v2: '\eb0e';
$one-icon-sentiment-very-satisfied-v2: '\eb0f';
$one-icon-share-material-v2: '\eb10';
$one-icon-shopping-basket-v2: '\eb11';
$one-icon-shopping-cart-v2: '\eb12';
$one-icon-sports-esports-v2: '\eb13';
$one-icon-stamp-v2: '\eb14';
$one-icon-star-border-v2: '\eb15';
$one-icon-star-half-v2: '\eb16';
$one-icon-stars-v2: '\eb17';
$one-icon-star-v2: '\eb18';
$one-icon-store-v2: '\eb19';
$one-icon-supervised-user-circle-v2: '\eb1a';
$one-icon-swap-horizontal-circle-v2: '\eb1b';
$one-icon-swap-horiz-v2: '\eb1c';
$one-icon-swap-vertical-circle-v2: '\eb1d';
$one-icon-swap-vert-v2: '\eb1e';
$one-icon-sync-alt-v2: '\eb1f';
$one-icon-sync-disabled-v2: '\eb20';
$one-icon-sync-material-v2: '\eb21';
$one-icon-theaters-v2: '\eb22';
$one-icon-thumb-down-v2: '\eb23';
$one-icon-thumb-up-v2: '\eb24';
$one-icon-thunder-v21: '\eb25';
$one-icon-timelapse-v2: '\eb26';
$one-icon-timer-v2: '\eb27';
$one-icon-trending-up-v2: '\eb28';
$one-icon-tv-material-v2: '\eb29';
$one-icon-update-v2: '\eb2a';
$one-icon-vegan-v2: '\eb2b';
$one-icon-vegetarian-v2: '\eb2c';
$one-icon-visibility-material-v2: '\eb2e';
$one-icon-visibility-off-material-v2: '\eb2f';
$one-icon-volumen-off-v2: '\eb30';
$one-icon-volumen-up-v2: '\eb31';
$one-icon-vpn-key-v2: '\eb32';
$one-icon-walnuts-v2: '\eb33';
$one-icon-watch-later-v2: '\eb34';
$one-icon-watch-v2: '\eb35';
$one-icon-work-outline-v2: '\eb36';
$one-icon-work-v2: '\eb37';
$one-icon-account-balance-v2: '\eb38';
$one-icon-account-balance-wallet-v2: '\eb39';
$one-icon-account-box-v2: '\eb3a';
$one-icon-account-circle-v2: '\eb3b';
$one-icon-add-box-v2: '\eb3c';
$one-icon-add-circle-outline-v2: '\eb3d';
$one-icon-add-circle-v2: '\eb3e';
$one-icon-add-material-v2: '\eb3f';
$one-icon-airplanemode-active-v2: '\eb40';
$one-icon-outline-menu: '\eb41';
$one-icon-outline-notification-v2: '\eb42';
$one-icon-outline-activity: '\eb43';
$one-icon-outline-happy-face: '\eb44';
$one-icon-outline-document-check: '\eb45';
$one-icon-outline-increase: '\eb46';
$one-icon-document-alert: '\eb47';
$one-icon-hyperlink: '\eb48';
$one-icon-accessibility: '\eb7a';
$one-icon-add-card: '\eb7b';
$one-icon-alpha: '\eb7c';
$one-icon-apartment: '\eb7d';
$one-icon-backup: '\eb7e';
$one-icon-bill: '\eb7f';
$one-icon-change-pin-v3: '\eb80';
$one-icon-calendar-money: '\eb81';
$one-icon-cc-amparada: '\eb82';
$one-icon-check-double: '\eb83';
$one-icon-check-square: '\eb84';
$one-icon-close-eyes: '\eb85';
$one-icon-close-square: '\eb86';
$one-icon-cloud: '\eb87';
$one-icon-color: '\eb88';
$one-icon-components: '\eb89';
$one-icon-inactive: '\eb8a';
$one-icon-contactless: '\eb8b';
$one-icon-copyright-v3: '\eb8c';
$one-icon-credit-card-v3: '\eb8d';
$one-icon-crystal-ball-ai: '\eb8e';
$one-icon-cut: '\eb8f';
$one-icon-verified-user-v2: '\eb2d';
$one-icon-danger: '\eb90';
$one-icon-dashboard: '\eb91';
