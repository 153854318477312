@import '../abstracts/variables';

/* Text Color */
.rp-text-white {
  color: $primary-text-light;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.rp-text-black {
  color: $black;
}

.rp-text-brand {
  color: $primary;
}

.rp-text-primary-dark {
  color: $primary-text-dark;
}

.rp-text-secondary-dark {
  color: $secondary-text-dark;
}

.rp-text-secondary-light {
  color: $secondary-text-light;
}

.rp-text-success {
  color: $success;
}

.rp-text-alert {
  color: $alert;
}

.rp-text-error {
  color: $error;
}

/* Text Blues */
.rp-text-blue-1 {
  color: $blue-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.rp-text-blue-2 {
  color: $blue-2;
}

.rp-text-blue-3 {
  color: $blue-3;
}

.rp-text-blue-4 {
  color: $blue-4;
}

.rp-text-blue-5 {
  color: $blue-5;
}

/* Text Purples */
.rp-text-purple-1 {
  color: $purple-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.rp-text-purple-2 {
  color: $purple-2;
}

.rp-text-purple-3 {
  color: $purple-3;
}

.rp-text-purple-4 {
  color: $purple-4;
}

.rp-text-purple-5 {
  color: $purple-5;
}

/* Text Oranges */
.rp-text-orange-1 {
  color: $orange-1;
}

.rp-text-orange-2 {
  color: $orange-2;
}

.rp-text-orange-3 {
  color: $orange-3;
}

.rp-text-orange-4 {
  color: $orange-4;
}

.rp-text-orange-5 {
  color: $orange-5;
}

.rp-text-orange-6 {
  color: $orange-6;
}

/* Text Greens */
.rp-text-green-1 {
  color: $green-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.rp-text-green-2 {
  color: $green-2;
}

.rp-text-green-3 {
  color: $green-3;
}

.rp-text-green-4 {
  color: $green-4;
}

/* Text Pinks */
.rp-text-pink-1 {
  color: $pink-1;
}

.rp-text-pink-2 {
  color: $pink-2;
}

.rp-text-pink-3 {
  color: $pink-3;
}

/* Text Grays */
.rp-text-gray-1 {
  color: $gray-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-2 {
  color: $gray-2;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-3 {
  color: $gray-3;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-4 {
  color: $gray-4;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-5 {
  color: $gray-5;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-6 {
  color: $gray-6;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-7 {
  color: $gray-7;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-8 {
  color: $gray-8;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

.rp-text-gray-9 {
  color: $gray-9;
}

.rp-text-gray-10 {
  color: $gray-10;
}

.rp-text-gray-11 {
  color: $gray-11;
}

.rp-text-gray-12 {
  color: $gray-12;
}

.rp-text-gray-13 {
  color: $gray-13;
}

/* Background Color */
.rp-bg-white {
  background-color: $white;
}

.rp-bg-black {
  background-color: $black;
}

.rp-bg-primary-light {
  background-color: $primary-background;
}

.rp-bg-primary-dark {
  background-color: $primary-background-dark;
}

.rp-bg-secondary-light {
  background-color: $secondary-background-dark;
}

.rp-bg-secondary-dark {
  background-color: $secondary-background-light;
}

.rp-bg-success {
  background-color: $success;
}

.rp-bg-alert {
  background-color: $alert;
}

.rp-bg-error {
  background-color: $error;
}

/* Background Blues */
.rp-bg-blue-1 {
  background-color: $blue-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.rp-bg-blue-2 {
  background-color: $blue-2;
}

.rp-bg-blue-3 {
  background-color: $blue-3;
}

.rp-bg-blue-4 {
  background-color: $blue-4;
}

.rp-bg-blue-5 {
  background-color: $blue-5;
}

/* Background Purples */
.rp-bg-purple-1 {
  background-color: $purple-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.rp-bg-purple-2 {
  background-color: $purple-2;
}

.rp-bg-purple-3 {
  background-color: $purple-3;
}

.rp-bg-purple-4 {
  background-color: $purple-4;
}

.rp-bg-purple-5 {
  background-color: $purple-5;
}

/* Background Oranges */
.rp-bg-orange-1 {
  background-color: $orange-1;
}

.rp-bg-orange-2 {
  background-color: $orange-2;
}

.rp-bg-orange-3 {
  background-color: $orange-3;
}

.rp-bg-orange-4 {
  background-color: $orange-4;
}

.rp-bg-orange-5 {
  background-color: $orange-5;
}

.rp-bg-orange-6 {
  background-color: $orange-6;
}

/* Background Greens */
.rp-bg-green-1 {
  background-color: $green-1;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.rp-bg-green-2 {
  background-color: $green-2;
}

.rp-bg-green-3 {
  background-color: $green-3;
}

.rp-bg-green-4 {
  background-color: $green-4;
}

/* Background Pinks */
.rp-bg-pink-1 {
  background-color: $pink-1;
}

.rp-bg-pink-2 {
  background-color: $pink-2;
}

.rp-bg-pink-3 {
  background-color: $pink-3;
}

/* Background Grays */
.rp-bg-gray-1 {
  background-color: $gray-1;
}

.rp-bg-gray-2 {
  background-color: $gray-2;
}

.rp-bg-gray-3 {
  background-color: $gray-3;
}

.rp-bg-gray-4 {
  background-color: $gray-4;
}

.rp-bg-gray-5 {
  background-color: $gray-5;
}

.rp-bg-gray-6 {
  background-color: $gray-6;
}

.rp-bg-gray-7 {
  background-color: $gray-7;
}

.rp-bg-gray-8 {
  background-color: $gray-8;
}

.rp-bg-gray-9 {
  background-color: $gray-9;
}

.rp-bg-gray-10 {
  background-color: $gray-10;
}

.rp-bg-gray-11 {
  background-color: $gray-11;
}

.rp-bg-gray-12 {
  background-color: $gray-12;
}

.rp-bg-gray-13 {
  background-color: $gray-13;
}
