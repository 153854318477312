@import '../utils/app.variables';
@import '../icons/icon-variables';

.mat-paginator {
  padding: 8px 0;

  .mat-paginator-range-label {
    order: 2;
    margin: 0;
    font-weight: $heading-font-weight;
    text-align: center;

    &::after {
      content: ' items';
    }
  }

  .mat-paginator-range-actions {
    font-size: $f-size;
    color: $secondary-text;

    button {
      background-color: transparent;
    }

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      padding-bottom: 2px;
      justify-content: center;
    }

    .mat-paginator-icon {
      width: 18px;
    }

    button:first-child {
      order: 1;
    }

    button:last-child {
      order: 3;
    }
  }

  .mat-paginator-page-size {
    .mat-paginator-page-size-label {
      display: none;
    }
    
    &::before {
      content: 'Show';
      font-size: $f-size;
      color: $secondary-text;
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-wrapper {
      border: solid 1px $gray-9;
      border-radius: 8px;
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border: 0;
      padding: 8px;
    }

    .mat-paginator-page-size-value {
      border: solid 1px $gray-9;
      border-radius: 8px;
      padding: 6px 12px;
      margin-left: 4px;
    }
  }

  .mat-select-arrow-wrapper {
    display: grid;

    &:before {
      content: $one-icon-chevron-down;
      font-family: '#{$icomoon-font-family}' !important;
  
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .mat-select-arrow {
      display: none;
    }
  }

  .mat-select-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mat-select-value {
      min-width: 25px;
    }
  }

  .mat-paginator-page-size-select {
    margin: 0 4px;
  }
}