@import '../abstracts/variables';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@mixin ellipsis($width) {
  width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
 * Base.
 */
.rp-button {
  font-family: $text-font;
  color: $secondary-text-dark;
  // TODO: Change padding to buttons paddings variables.
  padding: 8px $padding-l;
  font-size: $f-size;
  font-weight: $semibold-font;
  border-radius: $border-radius-m;
  line-height: 24px;;

  &[disabled] {
    color: $primary-disabled-color;
    pointer-events: none;
  }

  /*
 * Filled: add background color.
 */
  &.rp-filled {
    background-color: $secondary-text-light;
    color: $primary-text-light;

    &[disabled] {
      background: $primary-background;
      color: $primary-disabled-color;
    }
  }

  /*
   * Outline: add border color.
   */
  &.rp-outlined {
    color: $secondary-text-light;
    background: transparent;
    border: 1px solid currentColor;

    &[disabled] {
      background: $primary-background;
      color: $primary-disabled-color;
    }
  }

  /*
   * Colors: primary, success, accent, warn.
   */
  &:not([disabled]) {
    &.rp-primary {
      &.rp-outlined {
        color: $primary;
      }

      &.rp-filled {
        color: $primary-text-light;
        background-color: $primary;
      }
    }

    &.rp-success {
      &.rp-outlined {
        color: $success;
      }

      &.rp-filled {
        color: $primary-text-light;
        background-color: $success;
      }
    }

    &.rp-accent {
      &.rp-outlined {
        color: $error;
      }

      &.rp-filled {
        color: $primary-text-light;
        background-color: $error;
      }
    }

    &.rp-warn {
      &.rp-outlined {
        color: $alert;
      }

      &.rp-filled {
        color: $primary-text-light;
        background-color: $alert;
      }
    }

    &.rp-dark {
      &.rp-outlined {
        color: $primary-background-dark;
      }

      &.rp-filled {
        color: $primary-text-light;
        background-color: $primary-background-dark;
      }
    }
  }

  /*
   * SIZES: small, big.
   */
  &.rp-big {
    padding: $padding-l 30px;
    font-size: $f-size-xl;
  }

  &.rp-x-large{
    height: 56px;
    font-size: $f-size-l;
    padding: $padding-l 72px;
    @include ellipsis(343px);
  }
  
  &.rp-large{
    height: 56px;
    font-size: $f-size-l;
    padding: $padding-l 17px;
    @include ellipsis(215px);
  }

  &.rp-small {
    padding: 4px $padding-l;
    height: 32px;
    font-size: $f-size;
    border-radius: $border-radius-m;
  }

  /*
   * Raisable: add box shadow on hover.
   */
  &.rp-raisable:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }

  /*
   * Rounds: add circular border in button.
   */
  &.rp-rounded {
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 100%;
    padding: 0;

    &.rp-big {
      height: 60px;
      width: 60px;
    }
  }

   /*
   * Utils: utility tool for buttons.
   */
  &.no-background{
    background-color: transparent;
    border: none;
    color: $primary-disabled-color;
  }

  &.no-width{
    width: 100%;
  }
}
