@import '../sass-library/abstracts/variables';

.heading-font-weight {
  font-weight: $heading-font-weight;
}

.green-7 {
  color: $green-7;
}

.red-2 {
  color: $red-2;
}
