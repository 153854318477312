@import '../scss/index';
@import './brands-variables-theme';

one-dynamic-table {
  .mat-header-cell {
    text-transform: uppercase;
  }
}

one-data-title {
  .one-card-title-text {
    text-transform: uppercase;
  }
}

.mat-tab-group,
.mat-dialog-title,
.mat-table,
.mat-select,
.mat-option,
.mat-form-field-label {
  font-family: $font;
}