/* Fonts family */
$font: 'Poppins', Helvetica, Arial, sans-serif;

/* Typography */
$heading-font-weight: 600;

/* Font Sizes */
$f-size-xxxl: 32px;
$f-size-xxl: 24px;
$f-size-xl: 20px;
$f-size-l: 16px;
$f-size: 14px;
$f-size-s: 12px;
$f-size-xs: 10px;

/* Colors */
$primary: #4589ff;
$table-border-color: #e5e5e4;

/* Colors: Backgrounds */
$primary-background: #fafaf8;
$primary-background-dark: #0f0f24;
$secondary-background-dark: #706967;
$secondary-background-light: rgba($secondary-background-dark, 0.5);
$background-disabled: #f4f4f4;
$chip-state-default-background: #ffecd9;

/* Colors: Texts */
$primary-text: #0f0f24;
$primary-text-light: #ffffff;
$secondary-text: #706967;
$secondary-text-light: rgba($secondary-text, 0.7);

$primary-text-brand: $primary;
$text-gray-disabled: #b8b4b4;
$chip-state-default-color: #ff8919;
$title-green: #2ec4b6;
$red-decrease: #ff2525;
$secondary-text-light: rgba($secondary-text, 0.7);

/* Colors: Borders */
$primary-border-color: $primary;
$secondary-border-color: rgba($secondary-background-dark, 0.7);

/* Colors: Grays */
$gray-1: #f4f4f4;
$gray-2: #f8f9fb;
$gray-3: #e5edef;
$gray-4: #f9f9f8;
$gray-5: #f7f7f7;
$gray-6: #efece9;
$gray-7: #e5e5e4;
$gray-8: #e8e7e7;
$gray-9: #c7c7c7;
$gray-10: #b8b4b4;
$gray-11: #9faab7;
$gray-12: #7d7d7d;
$gray-13: #333333;
$gray-14: rgba(184, 180, 180, 0.1);
$gray-15: #f8f8f8;
$gray-16: #9aa9b9;
$gray-17: #f8f7f7;
$gray-18: #191919;
$gray-19: #f5f4f4;
$layout-background: #f9f9f8;
$disabled-color: #b3b9c2;

/* Colors: Blues */
$blue-1: #ecfcff;
$blue-2: #1d9ff3;
$blue-3: #1da1f2;
$blue-4: #1778f2;
$blue-5: #35497b;
$blue-6: #9ecbec;
$blue-7: #e6ebf1;
$blue-8: #f7f8f9;
$blue-9: #d1eeff;

/* Colors: Purples */
$purple-1: #dcdbff;
$purple-2: #8f8fc1;
$purple-3: #913cff;
$purple-4: #980dd9;
$purple-5: #6816fc;

/* Colors: Oranges */
$orange-1: #f8b595;
$orange-2: #ffe981;
$orange-3: #ffd559;
$orange-4: #ff8f57;
$orange-5: #ff7175;
$orange-6: #815d39;
$orange-7: #ffcd00;
$orange-8: #fff0ca;
$orange-9: #feecde;
$orange-10: #ff7d00;
$orange-accent: #ff9259;

/* Colors: Red */
$red-1: #ffdfdf;
$red-2: #ff2525;
$red-3: #ff441f;
$red-accent: #ff2426;

/* Colors: Greens */
$green-1: #d7fffe;
$green-2: #8bc1c1;
$green-3: #09bc8a;
$green-4: #0cc665;
$green-5: #c1fdd2;
$green-6: #f6fff9;

/* Colors: Pinks */
$pink-1: #ffe1d3;
$pink-2: #eaceb4;
$pink-3: #cfaa99;

/* Colors: Shadows */
$box-shadow: rgba(128, 98, 96, 0.16);

/* Colors: Alerts & Gradients */
$success: #0cc665;
$alert: #ff7d00;
$error: #ff2525;
$warning: #f75d63;

/* Transitions: form elements */
$form-element-transition: all 300ms ease;

/* Border radius*/
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 16px;

/* Containers */
$container-width-s: 1240px;
$container-width-m: 1360px;
$container-width-l: 1440px;

/* Break widths */
$break-high-desktop: 1670px;
$break-medium-desktop: 1440px;
$break-small-laptop: 1280px;
$break-tablet: 1024px;
$break-mobile: 768px;
$break-small-mobile: 480px;
$break-xs-mobile: 320px;

/* Fonts and icons base */
$base-url: 'https://images.rappi.com/web';
$base-url-fonts: 'https://images.rappi.com/web';
$base-url-icons: 'https://images.rappi.com/web/icons_fonts';

/* 2021 BBR style guide */
$primary-color: #2e2c36;
$secondary-color: #6a696e;
$disabled-color: #b3b9c2;
$disabled-background-color: #f0f1f3;
$border-color: #e6ebf1;
$platform-background-color: #f4f5f7;


/* status color */
$background-active: #dbf6e8;
$color-active: #0aa454;
$background-declined: #ffe9e9;
$color-declined: #ff2525;
$background-pending: #fff3da;
$color-pending: #ad832e;
$background-scheduled: #dae7ff;
$color-scheduled: #4589ff;
$background-ended: #f0f1f3;
$color-ended: #868d97;
$background-review: #ffdebe;
$color-review: #e47000;
$background-paused: #fff0f7;
$color-paused: #ee5396;
$background-waiting-approval: #e7e3f4;
$color-waiting-approval: #7356bf;
