mat-progress-bar.mat-progress-bar {
  height: 2px;

  .mat-progress-bar-fill {
    &:after {
      background-color: $gray-10;
    }
  }

  .mat-progress-bar-background {
    fill: none;
  }

  .mat-progress-bar-buffer {
    opacity: 0.2;
  }
}
