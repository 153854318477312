[mat-dialog-title].mat-dialog-title {
  margin: 0;
  line-height: unset;

  > :first-child {
    margin: 0 16px 16px;
  }
}

mat-dialog-container.mat-dialog-container {
  padding: 16px 0;
  border-radius: 15px;

  button {
    font-weight: 600;
  }
}

[mat-dialog-content].mat-dialog-content {
  margin: 0;
  padding: 0;
  border-top: 1px solid $gray-7;
  border-bottom: 1px solid $gray-7;
}

[mat-dialog-actions].mat-dialog-actions {
  min-height: unset;
  margin-bottom: unset;
  padding: 0;

  > :first-child {
    margin: 16px 16px 0;
  }
}
