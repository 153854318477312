@import '../utils/app.variables';

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'].rp-switch,
  input[type='radio'].rp-radio {

    --active: #{$primary-background-dark};
    --active-inner: white;
    --focus: 2px rgba(51, 41, 39, 0.3);
    --border: #{$gray-6};
    --border-hover: #{$primary-text};
    --background: white;
    --disabled: white;
    --disabled-inner: #{$gray-6};
    --unchecked: #{$gray-10};

    -webkit-appearance: none;
    -moz-appearance: none;
    height: 24px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 2px solid var(--bc, var(--border));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

    &:before {
      content: '';
      display: inline-block;
      opacity: 0.08;
      width: 100%;
      height: 100%;
      padding: 43%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: all 300ms ease;
      z-index: -1;
    }

    &:focus:before {
      background-color: $secondary-background-dark;
    }

    &:focus:not(:hover):before {
      background-color: transparent;
      transition: background-color 1s ease;
    }

    &:hover:before {
      background-color: $secondary-background-dark;
    }

    &:active:before,
    &:focus:active:before {
      opacity: 0.15;
      background-color: $secondary-background-dark;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }

    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }

    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 1;

      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }

      & + label {
        cursor: not-allowed;
      }

      &:before {
        opacity: 0;
      }
    }

    &:not(.rp-switch) {
      width: 24px;
      background: var(--b, var(--background));

      &:after {
        opacity: var(--o, 0);
      }

      &:checked {
        --o: 1;
      }
    }

    & + label {
      font-size: 14px;
      line-height: 24px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }

  input[type='checkbox'].rp-switch {
    --bc: var(--unchecked);

    &:disabled {
      --b: var(--disabled-inner);
      --bc: var(--disabled-inner);
      opacity: 0.4;

      &:after {
        background-color: white;
        opacity: 1;
        z-index: 1;
      }

      &:checked {
        opacity: 1;
      }
    }

    &:before {
      width: 20%;
      height: 40%;
      left: 22%;
      padding: 35%;
    }

    &:checked:before {
      left: 80%;
    }
  }
}

span.form-error {
  display: flex;

  > small {
    display: none;
    font-size: 13px;
    line-height: 18px;
    padding: 10px 20px;
    font-weight: normal;

    &:first-child {
      display: flex;
    }

    &:before {
      content: '*';
    }

    &.error {
      color: $error;
    }

    &.alert {
      color: $alert;
    }
  }
}
