@import "../abstracts/variables";

.bold {
  font-weight: $semibold-font;
}

.f-big-title {
  font-weight: $semibold-font;
  font-size: $f-size-xxxl;
  letter-spacing: -1.5px;
}

.f-title {
  font-weight: $semibold-font;
  font-size: $f-size-xxl;
  letter-spacing: -1px;
}

.f-headline {
  font-weight: $semibold-font;
  font-size: $f-size-xl;
  letter-spacing: -0.83;
}

.f-body {
  font-family: $text-font;
  font-size: $f-size-l;
  letter-spacing: -1;
}

.f-caption-1 {
  font-family: $text-font;
  font-size: $f-size;
  letter-spacing: 0;
}

.f-caption-2 {
  font-family: $text-font;
  font-size: $f-size-s;
  letter-spacing: 0;
}

.f-label {
  font-family: $text-font;
  font-size: $f-size-xs;
  letter-spacing: 0;
}

.f-subhead {
  font-weight: $semibold-font;
  font-size: $f-size-xs;
  letter-spacing: 1.2px;
}

.f-small {
  font-weight: $semibold-font;
  font-size: $f-size-xs - 2;
  letter-spacing: 0;
}

/* Fonts */
.rp-f-heading {
  font-weight: $semibold-font;
}
.rp-f-text {
  font-family: $text-font;
}
