@import './forms';
@import '../utils/app.variables';

$border-radius: 7px;
$border-width: 2px;
$check-icon: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17"><g fill="none" fill-rule="evenodd"><g fill="white"><g><g><g><path d="M6 10.78L3.687 8.467c-.26-.26-.68-.26-.94 0-.26.26-.26.68 0 .94l2.786 2.786c.26.26.68.26.94 0l7.054-7.053c.26-.26.26-.68 0-.94-.26-.26-.68-.26-.94 0L6 10.78z"/></g></g></g></g></g></svg>';
$hyphen-icon: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="17" width="17"><line x1="3" y1="8" x2="14" y2="8" style="stroke:white;stroke-width:2" /></svg>';

input[type='checkbox'].rp-checkbox {
  --disabled-color: #{$gray-6};

  cursor: pointer;
  position: relative;

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(200% - #{2 * $border-width});
    height: calc(200% - #{2 * $border-width});
    content: '';
    border-radius: $border-radius;
    border: $border-width solid $gray-10;
    background-color: white;
    box-sizing: border-box;
  }

  &.rp-primary {
    &:before {
      border-color: $primary;
    }
  }

  &.rp-success {
    &:before {
      border-color: $success;
    }
  }

  &.rp-accent {
    &:before {
      border-color: $error;
    }
  }

  &.rp-warn {
    &:before {
      border-color: $alert;
    }
  }

  &:checked,
  &:indeterminate {
    &:before {
      background-color: $primary-background-dark;
      background-position: center;
      background-size: cover;
      border-color: $primary-background-dark;
    }

    &.rp-primary {
      &:before {
        background-color: $primary;
        border-color: $primary;
      }
    }

    &.rp-success {
      &:before {
        background-color: $success;
        border-color: $success;
      }
    }

    &.rp-accent {
      &:before {
        background-color: $error;
        border-color: $error;
      }
    }

    &.rp-warn {
      &:before {
        background-color: $alert;
        border-color: $alert;
      }
    }
  }

  &:checked:before {
    background-image: url($check-icon);
  }

  &:indeterminate:before {
    background-image: url($hyphen-icon);
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    height: 300%;
    width: 300%;
    background-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
  }

  &:focus:after {
    background-color: rgba($gray-10, 0.28);
  }

  &:hover:after {
    background-color: rgba($gray-1, 0.56);
  }

  &:disabled {
    cursor: default;

    &:before {
      border-color: var(--disabled-color);
    }

    &:checked,
    &:indeterminate {
      &:before {
        background-color: var(--disabled-color);
        border-color: var(--disabled-color);
      }
    }

    &:focus:after,
    &:hover:after {
      background-color: transparent;
    }
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'].rp-switch {
    width: 48px;
    border-radius: 11px;
    --b: #{$gray-10};
    background: var(--b, var(--background));

    &:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: #ffffff;
      transform: translateX(var(--x, 0));
    }

    &:checked {
      --ab: var(--active-inner);
      --x: 17px;

      &:after {
        left: 10px;
      }
    }
  }
}
