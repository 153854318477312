/* Colors: Backgrounds */
$primary-background: #fafaf8;
$primary-background-dark: #0f0f24;
$primary-background-light: #fafaf8;
$primary-background-hover: #bcd4ff33;
$secondary-background: #706967;
$secondary-background-dark: #706967;
$secondary-background-light: rgba($secondary-background-dark, 0.5);
$active-background: #dae7ff;

/* Colors: Texts */
$primary-text: #2e2c36;
$primary-text-light: #ffffff;
$secondary-text: #6a696e;
$secondary-text-light: rgba($secondary-text, 0.7);
$banner-text: #262626;

/* Colors */
$primary: #4589ff;

/* Colors alerts */
$approved-status-color: #40d286;
$approved-status-background: #dbf6e8;
$pending-status-color: #ffc043;
$pending-status-background: #fff3da;
$declined-status-color: #ff2525;
$declined-status-background: #ffe9e9;

/* Fonts family */
$font: 'Poppins', Helvetica, Arial, sans-serif;

/* Typography */
$heading-font-weight: 600;

/* Colors: Grays */
$gray-1: #f4f4f4;
$gray-2: #f8f9fb;
$gray-3: #e5edef;
$gray-4: #f9f9f8;
$gray-5: #f7f7f7;
$gray-6: #efece9;
$gray-7: #e5e5e4;
$gray-8: #e8e7e7;
$gray-9: #c7c7c7;
$gray-10: #b8b4b4;
$gray-11: #9faab7;
$gray-12: #7d7d7d;
$gray-13: #333333;
$gray-14: rgba(184, 180, 180, 0.1);
$gray-15: #f8f8f8;
$gray-16: #9aa9b9;
$gray-17: #f8f7f7;
$gray-18: #191919;
$gray-19: #f5f4f4;
$layout-background: #f9f9f8;

/* Colors: Blues */
$blue-1: #ecfcff;
$blue-2: #1d9ff3;
$blue-3: #1da1f2;
$blue-4: #1778f2;
$blue-5: #35497b;
$blue-6: #9ecbec;
$blue-7: #e6ebf1;
$blue-8: #f7f8f9;
$blue-9: #d1eeff;
$blue-10: #e6eefb;

/* Colors: Purples */
$purple-1: #dcdbff;
$purple-2: #8f8fc1;
$purple-3: #913cff;
$purple-4: #980dd9;
$purple-5: #6816fc;
$purple-6: linear-gradient(to right, $purple-5, $purple-3);

/* Colors: Oranges */
$orange-1: #f8b595;
$orange-2: #ffe981;
$orange-3: #ffd559;
$orange-4: #ff8f57;
$orange-5: #ff7175;
$orange-6: #815d39;
$orange-7: #ffcd00;
$orange-8: #fff0ca;
$orange-9: #feecde;
$orange-10: #ff7d00;

/* Colors: Red */
$red-1: #ffdfdf;
$red-2: #ff2525;
$red-3: #ff441f;
$red-4: #ff7174;

/* Colors: Greens */
$green-1: #d7fffe;
$green-2: #8bc1c1;
$green-3: #09bc8a;
$green-4: #0cc665;
$green-5: #c1fdd2;
$green-6: #f6fff9;
$green-7: #29d884;
$green-8: #66dfaf;

/* Colors: Pinks */
$pink-1: #ffe1d3;
$pink-2: #eaceb4;
$pink-3: #cfaa99;

/* Colors: Shadows */
$box-shadow: rgba(128, 98, 96, 0.16);

$f-size-xxxl: 32px;
$f-size-xxl: 24px;
$f-size-xl: 20px;
$f-size-l: 16px;
$f-size: 14px;
$f-size-s: 12px;
$f-size-xs: 10px;

/* Border radius*/
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 16px;

/* Containers */
$container-width-s: 1240px;
$container-width-m: 1360px;
$container-width-l: 1440px;
$header-height-mobile: 4rem;
$header-height-desktop: 5rem;

/* Break widths */
$break-high-desktop: 1670px;
$break-medium-desktop: 1440px;
$break-small-laptop: 1280px;
$break-tablet: 1024px;
$break-mobile: 768px;
$break-small-mobile: 480px;
$break-xs-mobile: 320px;

/* Transitions: form elements */
$form-element-transition: all 300ms ease;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 600px,
  // Medium screen / tablet
  md: 960px,
  // Large screen / desktop
  lg: 1280px,
  // Extra large screen / wide desktop
  xl: 1920px
) !default;
