@import './forms';
@import '../utils/app.variables';

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'].rp-radio {
    --active: white;
    --active-inner: #{$primary-background-dark};
    border-radius: 50%;

    --bc: var(--unchecked);

    &.rp-primary {
      --active-inner: #{$primary};
      --bc: var(--active-inner);
    }

    &.rp-success {
      --active-inner: #{$success};
      --bc: var(--active-inner);
    }

    &.rp-accent {
      --active-inner: #{$error};
      --bc: var(--active-inner);
    }

    &.rp-warn {
      --active-inner: #{$alert};
      --bc: var(--active-inner);
    }

    &:checked {
      --bc: var(--active-inner);
      --s: 0.5;
    }

    &:after {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: var(--active-inner);
      opacity: 0;
      left: -2px;
      top: -2px;
      transform: scale(var(--s, 0.5));
    }

    &:disabled {
      --b: var(--disabled);
      --bc: var(--border);

      cursor: not-allowed;
      opacity: 0.9;

      &:checked {
        --b: var(--disabled);
        --bc: var(--border);
      }

      &:after {
        background: var(--disabled-inner);
      }

      & + label {
        cursor: not-allowed;
      }
    }
  }
}
