@import '../abstracts/variables';

.rp-divider {
  display: flex;
  align-items: center;
  color: $primary-text;
  line-height: normal;

  &:before,
  &:after {
    content: '';
    display: block;
    flex: 1 1 100%;
    height: 2px;
    background-color: $gray-6;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}
