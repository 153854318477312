@import '../abstracts/variables';
@import '../abstracts/keyframes';

/* Loader */
.rp-loader {
  top: calc(50vh - 20px);
  left: calc(50vw - 20px);
  width: 40px;
  height: 40px;
  border-radius: 150px;
  box-sizing: border-box;
  border: 4px solid $primary;
  border-top-color: rgba(0, 0, 0, 0.1);
  animation: loading 0.7s linear infinite;
}
