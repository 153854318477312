@import '../abstracts/variables';

@font-face {
  font-family: 'Poppins';
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url('#{$base-url-fonts}/Poppins-SemiBold.eot'),
    url('#{$base-url-fonts}/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('#{$base-url-fonts}/Poppins-SemiBold.woff2') format('woff2'),
    url('#{$base-url-fonts}/Poppins-SemiBold.woff') format('woff'),
    url('#{$base-url-fonts}/Poppins-SemiBold.ttf') format('truetype'),
    url('#{$base-url-fonts}/Poppins-SemiBold.svg#svgFontName') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('#{$base-url-fonts}/Poppins-Regular.eot'),
    url('#{$base-url-fonts}/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$base-url-fonts}/Poppins-Regular.woff2') format('woff2'),
    url('#{$base-url-fonts}/Poppins-Regular.woff') format('woff'),
    url('#{$base-url-fonts}/Poppins-Regular.ttf') format('truetype'),
    url('#{$base-url-fonts}/Poppins-Regular.svg#svgFontName') format('svg');
  font-weight: initial;
  font-style: normal;
  font-display: swap;
}
