@import '../../../../../../node_modules/@rappi/common/assets/theme/_brands-variables-theme.scss';

/* 2021 BBR style guide */
$primary-color: #2e2c36;
$secondary-color: #6a696e;
$disabled-color: #b3b9c2;
$disabled-background-color: #f0f1f3;
$border-color: #e6ebf1;
$platform-background-color: #f4f5f7;

$padding-layout: 24px;
$padding-layout-mobile: 15px;