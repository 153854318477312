@import '../utils/app.variables';

.rp-chip-list {
  .rp-chip {
    margin: 6px 0;
  }
}

.rp-chip {
  display: inline-flex;
  font-size: 14px;
  color: $text-gray-disabled;
  box-shadow: 0 6px 10px 0 rgba(128, 98, 96, 0.16);
  padding: 6px 10px;
  border-radius: 8px;

  strong {
    color: $secondary-text;
    margin: 0 6px;
    display: inline-flex;
    align-items: center;
  }

  img {
    cursor: pointer;
  }

  &.disabled {
    color: $text-gray-disabled;
    background-color: $background-disabled;
    pointer-events: none;

    strong {
      color: $secondary-text;
    }
  }

  &.state {
    background-color: $chip-state-default-background;
    color: $chip-state-default-color;

    &:hover {
      font-weight: bold;
    }
  }

  &.detail {
    border: solid 2px $gray-6;
  }

  &.selectable {
    cursor: pointer;
    transition: all 0.2s;

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.7;
    }

    &:hover {
      box-shadow: 0 10px 10px 0 rgba(128, 98, 96, 0.2);
      transform: scale(1.02);
    }

    &.selected {
      color: white;
      background-color: $primary-background-dark;

      img,
      i {
        filter: invert(1);
      }

      strong {
        color: white;
      }
    }

    img {
      margin-right: 4px;
    }

    strong {
      color: $primary-text;
      font-size: 14px;
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.selection {
    display: inline-flex;
    font-size: 12px;
    color: white;
    padding: 2px 10px;
    margin: 0;
    border-radius: 12px;
    background-color: $primary-background-dark;
    box-shadow: none;

    &.lighter {
      background-color: white;
      border: 1px solid $gray-6;
      color: $primary-text;
    }
  }

  &.rp-filled,
  &.rp-outlined {
    display: inline-flex;
    font-size: 12px;
    padding: 2px 10px;
    margin: 0;
    border-radius: 12px;
  }

  &.rp-filled {
    color: white;
    background-color: rgba(112, 105, 103, 0.7);
  }

  &.rp-outlined {
    color: rgba(112, 105, 103, 0.7);
    background-color: white;
    border: 1px solid currentColor;
  }

  &.rp-primary {
    &.rp-outlined {
      color: $primary;
    }

    &.rp-filled {
      color: $primary-text-light;
      background-color: $primary;
    }
  }

  &.rp-success {
    &.rp-outlined {
      color: $success;
    }

    &.rp-filled {
      color: $primary-text-light;
      background-color: $success;
    }
  }

  &.rp-accent {
    &.rp-outlined {
      color: $error;
    }

    &.rp-filled {
      color: $primary-text-light;
      background-color: $error;
    }
  }

  &.rp-warn {
    &.rp-outlined {
      color: $alert;
    }

    &.rp-filled {
      color: $primary-text-light;
      background-color: $alert;
    }
  }

  &.rp-rounded {
    justify-content: center;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 100%;
    padding: 0;
  }
}
