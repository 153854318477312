@import '../abstracts/keyframes';

.fade-in-up {
  animation-name: fadeInUp;
}

.fade-in-right {
  animation-name: fadeInRight;
}

.fadeInLeft {
  animation-name: fadeInRight;
}
