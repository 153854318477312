/* Colors */
$primary: #4589ff;
$white: #ffffff;
$black: #000000;

/* Colors: Backgrounds */
$primary-background: #f0f1f3;
$primary-background-dark: #0f0f24;
$secondary-background-dark: #706967;
$secondary-background-light: rgba($secondary-background-dark, 0.5);

/* Colors: Texts */
$primary-text-dark: #0f0f24;
$primary-text-light: #ffffff;
$primary-text-brand: $primary;

$secondary-text-dark: #706967;
$secondary-text-light: rgba($secondary-text-dark, 0.7);

/* Colors: Borders */
$primary-border-color: $primary;
$secondary-border-color: rgba($secondary-background-dark, 0.7);

/* Colors: Disabled */
$primary-disabled-color: #b3b9c2;

/* Colors: Grays */
$gray-1: #f4f4f4;
$gray-2: #f8f9fb;
$gray-3: #e5edef;
$gray-4: #f9f9f8;
$gray-5: #f7f7f7;
$gray-6: #efece9;
$gray-7: #e5e5e4;
$gray-8: #e8e7e7;
$gray-9: #c7c7c7;
$gray-10: #b8b4b4;
$gray-11: #9faab7;
$gray-12: #7d7d7d;
$gray-13: #333333;

/* Colors: Blues */
$blue-1: #ecfcff;
$blue-2: #1d9ff3;
$blue-3: #1da1f2;
$blue-4: #1778f2;
$blue-5: #35497b;

/* Colors: Purples */
$purple-1: #dcdbff;
$purple-2: #8f8fc1;
$purple-3: #913cff;
$purple-4: #980dd9;
$purple-5: #6816fc;

/* Colors: Oranges */
$orange-1: #f8b595;
$orange-2: #ffe981;
$orange-3: #ffd559;
$orange-4: #ff8f57;
$orange-5: #ff7175;
$orange-6: #815d39;

/* Colors: Greens */
$green-1: #d7fffe;
$green-2: #8bc1c1;
$green-3: #09bc8a;
$green-4: #0cc665;

/* Colors: Pinks */
$pink-1: #ffe1d3;
$pink-2: #eaceb4;
$pink-3: #cfaa99;

/* Colors: Alerts */
$success: #0cc665;
$alert: #ff7d00;
$error: #ff2425;
$warning: #f75d63;

/* Focus: Shadow */
$shadow-focus: 0 6px 10px 0 rgba(128, 98, 96, 0.16);

/* Paddings */
$padding-xl: 20px;
$padding-l: 16px;

/* Fonts */
$text-font: 'Poppins', Helvetica, Arial, sans-serif;
$semibold-font: 600;

$f-size-xxxl: 32px;
$f-size-xxl: 24px;
$f-size-xl: 20px;
$f-size-l: 16px;
$f-size: 14px;
$f-size-s: 12px;
$f-size-xs: 10px;

/* Border radius*/
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 16px;

/* Containers */
$container-width-s: 1240px;
$container-width-m: 1360px;
$container-width-l: 1440px;

/* Break widths */
$break-high-desktop: 1670px;
$break-medium-desktop: 1440px;
$break-small-laptop: 1280px;
$break-tablet: 1024px;
$break-mobile: 768px;
$break-small-mobile: 480px;
$break-xs-mobile: 320px;

/* Fonts and icons base */
$base-url: 'https://images.rappi.com/web';
$base-url-fonts: 'https://images.rappi.com/web';
$base-url-icons: 'https://images.rappi.com/web/icons_fonts';
