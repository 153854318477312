@import 'icon-variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?almbmn');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?almbmn#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?almbmn') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?almbmn') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?almbmn##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='one-icon-'],
[class*=' one-icon-'] {
  &:before,
  &:after {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

[class^='one-icon-duotone'],
[class*=' one-icon-duotone'] {
  &:after {
    opacity: 0.5;
    margin-left: -1em;
  }
}

.one-icon-document-add {
  &:before {
    content: $one-icon-document-add;
  }
}
.one-icon-document-clock {
  &:before {
    content: $one-icon-document-clock;
  }
}
.one-icon-document-credit {
  &:before {
    content: $one-icon-document-credit;
  }
}
.one-icon-download1 {
  &:before {
    content: $one-icon-download1;
  }
}
.one-icon-file-coin {
  &:before {
    content: $one-icon-file-coin;
  }
}
.one-icon-file-fav {
  &:before {
    content: $one-icon-file-fav;
  }
}
.one-icon-file-gif {
  &:before {
    content: $one-icon-file-gif;
  }
}
.one-icon-folder {
  &:before {
    content: $one-icon-folder;
  }
}
.one-icon-government-v3 {
  &:before {
    content: $one-icon-government-v3;
  }
}
.one-icon-graph-v3 {
  &:before {
    content: $one-icon-graph-v3;
  }
}
.one-icon-hand-fist {
  &:before {
    content: $one-icon-hand-fist;
  }
}
.one-icon-hand-pointing {
  &:before {
    content: $one-icon-hand-pointing;
  }
}
.one-icon-hand {
  &:before {
    content: $one-icon-hand;
  }
}
.one-icon-house-v3 {
  &:before {
    content: $one-icon-house-v3;
  }
}
.one-icon-image {
  &:before {
    content: $one-icon-image;
  }
}
.one-icon-letters-ai {
  &:before {
    content: $one-icon-letters-ai;
  }
}
.one-icon-link-v3 {
  &:before {
    content: $one-icon-link-v3;
  }
}
.one-icon-login {
  &:before {
    content: $one-icon-login;
  }
}
.one-icon-message-v3 {
  &:before {
    content: $one-icon-message-v3;
  }
}
.one-icon-money-circle {
  &:before {
    content: $one-icon-money-circle;
  }
}
.one-icon-money-lock {
  &:before {
    content: $one-icon-money-lock;
  }
}
.one-icon-more-circle {
  &:before {
    content: $one-icon-more-circle;
  }
}
.one-icon-numbers {
  &:before {
    content: $one-icon-numbers;
  }
}
.one-icon-open-eyes {
  &:before {
    content: $one-icon-open-eyes;
  }
}
.one-icon-order {
  &:before {
    content: $one-icon-order;
  }
}
.one-icon-padlock-open-v3 {
  &:before {
    content: $one-icon-padlock-open-v3;
  }
}
.one-icon-paper-download {
  &:before {
    content: $one-icon-paper-download;
  }
}
.one-icon-paper-upload {
  &:before {
    content: $one-icon-paper-upload;
  }
}
.one-icon-password-v3 {
  &:before {
    content: $one-icon-password-v3;
  }
}
.one-icon-pen-ai {
  &:before {
    content: $one-icon-pen-ai;
  }
}
.one-icon-pse {
  &:before {
    content: $one-icon-pse;
  }
}
.one-icon-rappicredits-v3 {
  &:before {
    content: $one-icon-rappicredits-v3;
  }
}
.one-icon-save-v3 {
  &:before {
    content: $one-icon-save-v3;
  }
}
.one-icon-scan {
  &:before {
    content: $one-icon-scan;
  }
}
.one-icon-share-v3 {
  &:before {
    content: $one-icon-share-v3;
  }
}
.one-icon-shop-v3 {
  &:before {
    content: $one-icon-shop-v3;
  }
}
.one-icon-social-tiktok {
  &:before {
    content: $one-icon-social-tiktok;
  }
}
.one-icon-sort-by {
  &:before {
    content: $one-icon-sort-by;
  }
}
.one-icon-square-download {
  &:before {
    content: $one-icon-square-download;
  }
}
.one-icon-thumbtack {
  &:before {
    content: $one-icon-thumbtack;
  }
}
.one-icon-tools-v3 {
  &:before {
    content: $one-icon-tools-v3;
  }
}
.one-icon-transaction-report {
  &:before {
    content: $one-icon-transaction-report;
  }
}
.one-icon-travel-plane {
  &:before {
    content: $one-icon-travel-plane;
  }
}
.one-icon-turbo {
  &:before {
    content: $one-icon-turbo;
  }
}
.one-icon-undo {
  &:before {
    content: $one-icon-undo;
  }
}
.one-icon-upload-v3 {
  &:before {
    content: $one-icon-upload-v3;
  }
}
.one-icon-user-pro-v3 {
  &:before {
    content: $one-icon-user-pro-v3;
  }
}
.one-icon-video-v3 {
  &:before {
    content: $one-icon-video-v3;
  }
}
.one-icon-wifi-v3 {
  &:before {
    content: $one-icon-wifi-v3;
  }
}
.one-icon-wrench {
  &:before {
    content: $one-icon-wrench;
  }
}
.one-icon-money-bag {
  &:before {
    content: $one-icon-money-bag;
  }
}
.one-icon-users {
  &:before {
    content: $one-icon-users;
  }
}
.one-icon-food {
  &:before {
    content: $one-icon-food;
  }
}
.one-icon-phone {
  &:before {
    content: $one-icon-phone;
  }
}
.one-icon-rappipay {
  &:before {
    content: $one-icon-rappipay;
  }
}
.one-icon-credit-card {
  &:before {
    content: $one-icon-credit-card;
  }
}
.one-icon-cash {
  &:before {
    content: $one-icon-cash;
  }
}
.one-icon-calendar {
  &:before {
    content: $one-icon-calendar;
  }
}
.one-icon-female {
  &:before {
    content: $one-icon-female;
  }
}
.one-icon-male {
  &:before {
    content: $one-icon-male;
  }
}
.one-icon-wallet {
  &:before {
    content: $one-icon-wallet;
  }
}
.one-icon-torn-paper {
  &:before {
    content: $one-icon-torn-paper;
  }
}
.one-icon-arrow-down {
  &:before {
    content: $one-icon-arrow-down;
  }
}
.one-icon-briefcase {
  &:before {
    content: $one-icon-briefcase;
  }
}
.one-icon-flag {
  &:before {
    content: $one-icon-flag;
  }
}
.one-icon-flag-check {
  &:before {
    content: $one-icon-flag-check;
  }
}
.one-icon-graph {
  &:before {
    content: $one-icon-graph;
  }
}
.one-icon-target {
  &:before {
    content: $one-icon-target;
  }
}
.one-icon-pin {
  &:before {
    content: $one-icon-pin;
  }
}
.one-icon-tag {
  &:before {
    content: $one-icon-tag;
  }
}
.one-icon-tags {
  &:before {
    content: $one-icon-tags;
  }
}
.one-icon-megaphone {
  &:before {
    content: $one-icon-megaphone;
  }
}
.one-icon-paper-clock {
  &:before {
    content: $one-icon-paper-clock;
  }
}
.one-icon-clipboard-check {
  &:before {
    content: $one-icon-clipboard-check;
  }
}
.one-icon-tooltip-check {
  &:before {
    content: $one-icon-tooltip-check;
  }
}
.one-icon-user {
  &:before {
    content: $one-icon-user;
  }
}
.one-icon-user-plus {
  &:before {
    content: $one-icon-user-plus;
  }
}
.one-icon-user-minus {
  &:before {
    content: $one-icon-user-minus;
  }
}
.one-icon-redo {
  &:before {
    content: $one-icon-redo;
  }
}
.one-icon-bifurcation {
  &:before {
    content: $one-icon-bifurcation;
  }
}
.one-icon-repeat {
  &:before {
    content: $one-icon-repeat;
  }
}
.one-icon-heart {
  &:before {
    content: $one-icon-heart;
  }
}
.one-icon-heart-broken {
  &:before {
    content: $one-icon-heart-broken;
  }
}
.one-icon-info {
  &:before {
    content: $one-icon-info;
  }
}
.one-icon-check-circle {
  &:before {
    content: $one-icon-check-circle;
  }
}
.one-icon-copy {
  &:before {
    content: $one-icon-copy;
  }
}
.one-icon-caret-up {
  &:before {
    content: $one-icon-caret-up;
  }
}
.one-icon-download {
  &:before {
    content: $one-icon-download;
  }
}
.one-icon-van {
  &:before {
    content: $one-icon-van;
  }
}
.one-icon-external {
  &:before {
    content: $one-icon-external;
  }
}
.one-icon-link {
  &:before {
    content: $one-icon-link;
  }
}
.one-icon-delete {
  &:before {
    content: $one-icon-delete;
  }
}
.one-icon-check {
  &:before {
    content: $one-icon-check;
  }
}
.one-icon-wrong {
  &:before {
    content: $one-icon-wrong;
  }
}
.one-icon-search {
  &:before {
    content: $one-icon-search;
  }
}
.one-icon-bike {
  &:before {
    content: $one-icon-bike;
  }
}
.one-icon-trash {
  &:before {
    content: $one-icon-trash;
  }
}
.one-icon-cap {
  &:before {
    content: $one-icon-cap;
  }
}
.one-icon-diagram {
  &:before {
    content: $one-icon-diagram;
  }
}
.one-icon-alert-rounded {
  &:before {
    content: $one-icon-alert-rounded;
  }
}
.one-icon-pencil {
  &:before {
    content: $one-icon-pencil;
  }
}
.one-icon-shopping-cart {
  &:before {
    content: $one-icon-shopping-cart;
  }
}
.one-icon-semi-scroll {
  &:before {
    content: $one-icon-semi-scroll;
  }
}
.one-icon-sliders-v {
  &:before {
    content: $one-icon-sliders-v;
  }
}
.one-icon-menu-hamburger {
  &:before {
    content: $one-icon-menu-hamburger;
  }
}
.one-icon-user-circle-1 {
  &:before {
    content: $one-icon-user-circle-1;
  }
}
.one-icon-user-circle-2 {
  &:before {
    content: $one-icon-user-circle-2;
  }
}
.one-icon-arrow-left-1 {
  &:before {
    content: $one-icon-arrow-left-1;
  }
}
.one-icon-arrow-left-2 {
  &:before {
    content: $one-icon-arrow-left-2;
  }
}
.one-icon-building-1 {
  &:before {
    content: $one-icon-building-1;
  }
}
.one-icon-building-2 {
  &:before {
    content: $one-icon-building-2;
  }
}
.one-icon-award-1 {
  &:before {
    content: $one-icon-award-1;
  }
}
.one-icon-award-2 {
  &:before {
    content: $one-icon-award-2;
  }
}
.one-icon-user-plus-frame-1 {
  &:before {
    content: $one-icon-user-plus-frame-1;
  }
}
.one-icon-user-plus-frame-2 {
  &:before {
    content: $one-icon-user-plus-frame-2;
  }
}
.one-icon-keyboard-1 {
  &:before {
    content: $one-icon-keyboard-1;
  }
}
.one-icon-keyboard-2 {
  &:before {
    content: $one-icon-keyboard-2;
  }
}
.one-icon-user-frame-1 {
  &:before {
    content: $one-icon-user-frame-1;
  }
}
.one-icon-user-frame-2 {
  &:before {
    content: $one-icon-user-frame-2;
  }
}
.one-icon-coins-tag-1 {
  &:before {
    content: $one-icon-coins-tag-1;
  }
}
.one-icon-coins-tag-2 {
  &:before {
    content: $one-icon-coins-tag-2;
  }
}
.one-icon-phone-1 {
  &:before {
    content: $one-icon-phone-1;
  }
}
.one-icon-phone-2 {
  &:before {
    content: $one-icon-phone-2;
  }
}
.one-icon-video-plus-1 {
  &:before {
    content: $one-icon-video-plus-1;
  }
}
.one-icon-video-plus-2 {
  &:before {
    content: $one-icon-video-plus-2;
  }
}
.one-icon-badge-percent-1 {
  &:before {
    content: $one-icon-badge-percent-1;
  }
}
.one-icon-badge-percent-2 {
  &:before {
    content: $one-icon-badge-percent-2;
  }
}
.one-icon-present-1 {
  &:before {
    content: $one-icon-present-1;
  }
}
.one-icon-present-2 {
  &:before {
    content: $one-icon-present-2;
  }
}
.one-icon-magnifying-glass-1 {
  &:before {
    content: $one-icon-magnifying-glass-1;
  }
}
.one-icon-magnifying-glass-2 {
  &:before {
    content: $one-icon-magnifying-glass-2;
  }
}
.one-icon-plus-circle {
  &:before {
    content: $one-icon-plus-circle;
  }
}
.one-icon-key-1 {
  &:before {
    content: $one-icon-key-1;
  }
}
.one-icon-key-2 {
  &:before {
    content: $one-icon-key-2;
  }
}
.one-icon-ecommerce-1 {
  &:before {
    content: $one-icon-ecommerce-1;
  }
}
.one-icon-ecommerce-2 {
  &:before {
    content: $one-icon-ecommerce-2;
  }
}
.one-icon-dollar-sign {
  &:before {
    content: $one-icon-dollar-sign;
  }
}
.one-icon-times {
  &:before {
    content: $one-icon-times;
  }
}
.one-icon-ban {
  &:before {
    content: $one-icon-ban;
  }
}
.one-icon-chevron-left {
  &:before {
    content: $one-icon-chevron-left;
  }
}
.one-icon-horizontal-link {
  &:before {
    content: $one-icon-horizontal-link;
  }
}
.one-icon-user-2 {
  &:before {
    content: $one-icon-user-2;
  }
}
.one-icon-lightning {
  &:before {
    content: $one-icon-lightning;
  }
}
.one-icon-poster-dollar-1 {
  &:before {
    content: $one-icon-poster-dollar-1;
  }
}
.one-icon-poster-dollar-2 {
  &:before {
    content: $one-icon-poster-dollar-2;
  }
}
.one-icon-email-1 {
  &:before {
    content: $one-icon-email-1;
  }
}
.one-icon-email-2 {
  &:before {
    content: $one-icon-email-2;
  }
}
.one-icon-phone-plus-1 {
  &:before {
    content: $one-icon-phone-plus-1;
  }
}
.one-icon-phone-plus-2 {
  &:before {
    content: $one-icon-phone-plus-2;
  }
}
.one-icon-magic-stick-1 {
  &:before {
    content: $one-icon-magic-stick-1;
  }
}
.one-icon-magic-stick-2 {
  &:before {
    content: $one-icon-magic-stick-2;
  }
}
.one-icon-box-3d-1 {
  &:before {
    content: $one-icon-box-3d-1;
  }
}
.one-icon-box-3d-2 {
  &:before {
    content: $one-icon-box-3d-2;
  }
}
.one-icon-user-left-frame-1 {
  &:before {
    content: $one-icon-user-left-frame-1;
  }
}
.one-icon-user-left-frame-2 {
  &:before {
    content: $one-icon-user-left-frame-2;
  }
}
.one-icon-reload {
  &:before {
    content: $one-icon-reload;
  }
}
.one-icon-exit {
  &:before {
    content: $one-icon-exit;
  }
}
.one-icon-wheel {
  &:before {
    content: $one-icon-wheel;
  }
}
.one-icon-question-circle {
  &:before {
    content: $one-icon-question-circle;
  }
}
.one-icon-chevron-down {
  &:before {
    content: $one-icon-chevron-down;
  }
}
.one-icon-info-flat {
  &:before {
    content: $one-icon-info-flat;
  }
}
.one-icon-arrow-to-bottom {
  &:before {
    content: $one-icon-arrow-to-bottom;
  }
}
.one-icon-cancel-filled {
  &:before {
    content: $one-icon-cancel-filled;
  }
}
.one-icon-language {
  &:before {
    content: $one-icon-language;
  }
}
.one-icon-tools {
  &:before {
    content: $one-icon-tools;
  }
}
.one-icon-check-filled {
  &:before {
    content: $one-icon-check-filled;
  }
}
.one-icon-mark-plus {
  &:before {
    content: $one-icon-mark-plus;
  }
}
.one-icon-short-arrow-down {
  &:before {
    content: $one-icon-short-arrow-down;
  }
}
.one-icon-chart-bars {
  &:before {
    content: $one-icon-chart-bars;
  }
}
.one-icon-three-dots {
  &:before {
    content: $one-icon-three-dots;
  }
}
.one-icon-eye {
  &:before {
    content: $one-icon-eye;
  }
}
.one-icon-user-tooltip {
  &:before {
    content: $one-icon-user-tooltip;
  }
}
.one-icon-keyboard {
  &:before {
    content: $one-icon-keyboard;
  }
}
.one-icon-present {
  &:before {
    content: $one-icon-present;
  }
}
.one-icon-settings {
  &:before {
    content: $one-icon-settings;
  }
}
.one-icon-diagonal-tag {
  &:before {
    content: $one-icon-diagonal-tag;
  }
}
.one-icon-user-circle {
  &:before {
    content: $one-icon-user-circle;
  }
}
.one-icon-user-plus-frame {
  &:before {
    content: $one-icon-user-plus-frame;
  }
}
.one-icon-star-solid {
  &:before {
    content: $one-icon-star-solid;
  }
}
.one-icon-star-regular {
  &:before {
    content: $one-icon-star-regular;
  }
}
.one-icon-star-half-alt {
  &:before {
    content: $one-icon-star-half-alt;
  }
}
.one-icon-piled-up {
  &:before {
    content: $one-icon-piled-up;
  }
}
.one-icon-clock {
  &:before {
    content: $one-icon-clock;
  }
}
.one-icon-video {
  &:before {
    content: $one-icon-video;
  }
}
.one-icon-paper-check {
  &:before {
    content: $one-icon-paper-check;
  }
}
.one-icon-compare-arrows {
  &:before {
    content: $one-icon-compare-arrows;
  }
}
.one-icon-light {
  &:before {
    content: $one-icon-light;
  }
}
.one-icon-exclamation-circle {
  &:before {
    content: $one-icon-exclamation-circle;
  }
}
.one-icon-plus {
  &:before {
    content: $one-icon-plus;
  }
}
.one-icon-paper-plan-fill {
  &:before {
    content: $one-icon-paper-plan-fill;
  }
}
.one-icon-trend-up-arrow {
  &:before {
    content: $one-icon-trend-up-arrow;
  }
}
.one-icon-reset-password {
  &:before {
    content: $one-icon-reset-password;
  }
}
.one-icon-key {
  &:before {
    content: $one-icon-key;
  }
}
.one-icon-user-box {
  &:before {
    content: $one-icon-user-box;
  }
}
.one-icon-eye-solid {
  &:before {
    content: $one-icon-eye-solid;
  }
}
.one-icon-eye-solid-slash {
  &:before {
    content: $one-icon-eye-solid-slash;
  }
}
.one-icon-offline-bolt {
  &:before {
    content: $one-icon-offline-bolt;
  }
}
.one-icon-bookmark {
  &:before {
    content: $one-icon-bookmark;
  }
}
.one-icon-oval-bar-chart {
  &:before {
    content: $one-icon-oval-bar-chart;
  }
}
.one-icon-general-lifebuoy {
  &:before {
    content: $one-icon-general-lifebuoy;
  }
}
.one-icon-outline-notification {
  &:before {
    content: $one-icon-outline-notification;
  }
}
.one-icon-outline-coupon {
  &:before {
    content: $one-icon-outline-coupon;
  }
}
.one-icon-filled-favorite {
  &:before {
    content: $one-icon-filled-favorite;
  }
}
.one-icon-general-add-circle-outline {
  &:before {
    content: $one-icon-general-add-circle-outline;
  }
}
.one-icon-general-remove-circle-outline {
  &:before {
    content: $one-icon-general-remove-circle-outline;
  }
}
.one-icon-outline-flash {
  &:before {
    content: $one-icon-outline-flash;
  }
}
.one-icon-outline-hotel {
  &:before {
    content: $one-icon-outline-hotel;
  }
}
.one-icon-outline-calendar {
  &:before {
    content: $one-icon-outline-calendar;
  }
}
.one-icon-outline-wallet {
  &:before {
    content: $one-icon-outline-wallet;
  }
}
.one-icon-general-timelapse {
  &:before {
    content: $one-icon-general-timelapse;
  }
}
.one-icon-store {
  &:before {
    content: $one-icon-store;
  }
}
.one-icon-trash-outline {
  &:before {
    content: $one-icon-trash-outline;
  }
}
.one-icon-trash-1 {
  &:before {
    content: $one-icon-trash-1;
  }
}
.one-icon-filled-activity {
  &:before {
    content: $one-icon-filled-activity;
  }
}
.one-icon-outline-truck {
  &:before {
    content: $one-icon-outline-truck;
  }
}
.one-icon-outline-edit {
  &:before {
    content: $one-icon-outline-edit;
  }
}
.one-icon-add {
  &:before {
    content: $one-icon-add;
  }
}
.one-icon-headset {
  &:before {
    content: $one-icon-headset;
  }
}
.one-icon-general-info-filled {
  &:before {
    content: $one-icon-general-info-filled;
  }
}
.one-icon-add-money-v2 {
  &:before {
    content: $one-icon-add-money-v2;
  }
}
.one-icon-add-phone-v2 {
  &:before {
    content: $one-icon-add-phone-v2;
  }
}
.one-icon-add-profile-v2 {
  &:before {
    content: $one-icon-add-profile-v2;
  }
}
.one-icon-add-v2 {
  &:before {
    content: $one-icon-add-v2;
  }
}
.one-icon-admin-v2 {
  &:before {
    content: $one-icon-admin-v2;
  }
}
.one-icon-arrow-down-v2 {
  &:before {
    content: $one-icon-arrow-down-v2;
  }
}
.one-icon-arrow-left-v2 {
  &:before {
    content: $one-icon-arrow-left-v2;
  }
}
.one-icon-arrow-right-v2 {
  &:before {
    content: $one-icon-arrow-right-v2;
  }
}
.one-icon-arrow-up-v2 {
  &:before {
    content: $one-icon-arrow-up-v2;
  }
}
.one-icon-atm-v2 {
  &:before {
    content: $one-icon-atm-v2;
  }
}
.one-icon-bad-face-v2 {
  &:before {
    content: $one-icon-bad-face-v2;
  }
}
.one-icon-balance-v2 {
  &:before {
    content: $one-icon-balance-v2;
  }
}
.one-icon-bank-v2 {
  &:before {
    content: $one-icon-bank-v2;
  }
}
.one-icon-bars-code-v2 {
  &:before {
    content: $one-icon-bars-code-v2;
  }
}
.one-icon-basket-v2 {
  &:before {
    content: $one-icon-basket-v2;
  }
}
.one-icon-beauty-v2 {
  &:before {
    content: $one-icon-beauty-v2;
  }
}
.one-icon-bill-ok-v2 {
  &:before {
    content: $one-icon-bill-ok-v2;
  }
}
.one-icon-bowl-v2 {
  &:before {
    content: $one-icon-bowl-v2;
  }
}
.one-icon-box-v2 {
  &:before {
    content: $one-icon-box-v2;
  }
}
.one-icon-burger-v2 {
  &:before {
    content: $one-icon-burger-v2;
  }
}
.one-icon-bus-v2 {
  &:before {
    content: $one-icon-bus-v2;
  }
}
.one-icon-cable-tv-v2 {
  &:before {
    content: $one-icon-cable-tv-v2;
  }
}
.one-icon-cake-v2 {
  &:before {
    content: $one-icon-cake-v2;
  }
}
.one-icon-calendar-1-v2 {
  &:before {
    content: $one-icon-calendar-1-v2;
  }
}
.one-icon-calendar-in-v2 {
  &:before {
    content: $one-icon-calendar-in-v2;
  }
}
.one-icon-calendar-v2 {
  &:before {
    content: $one-icon-calendar-v2;
  }
}
.one-icon-camera-v2 {
  &:before {
    content: $one-icon-camera-v2;
  }
}
.one-icon-canceled-order-v2 {
  &:before {
    content: $one-icon-canceled-order-v2;
  }
}
.one-icon-card-reload-v2 {
  &:before {
    content: $one-icon-card-reload-v2;
  }
}
.one-icon-car-v2 {
  &:before {
    content: $one-icon-car-v2;
  }
}
.one-icon-cashback-pe-v2 {
  &:before {
    content: $one-icon-cashback-pe-v2;
  }
}
.one-icon-cashback-v2 {
  &:before {
    content: $one-icon-cashback-v2;
  }
}
.one-icon-cemetery-v2 {
  &:before {
    content: $one-icon-cemetery-v2;
  }
}
.one-icon-change-pin-v2 {
  &:before {
    content: $one-icon-change-pin-v2;
  }
}
.one-icon-check-protection-v2 {
  &:before {
    content: $one-icon-check-protection-v2;
  }
}
.one-icon-check-v2 {
  &:before {
    content: $one-icon-check-v2;
  }
}
.one-icon-chef-hat-v2 {
  &:before {
    content: $one-icon-chef-hat-v2;
  }
}
.one-icon-chronometer-v2 {
  &:before {
    content: $one-icon-chronometer-v2;
  }
}
.one-icon-clock-v2 {
  &:before {
    content: $one-icon-clock-v2;
  }
}
.one-icon-close-small-v2 {
  &:before {
    content: $one-icon-close-small-v2;
  }
}
.one-icon-close-v2 {
  &:before {
    content: $one-icon-close-v2;
  }
}
.one-icon-cloud-v2 {
  &:before {
    content: $one-icon-cloud-v2;
  }
}
.one-icon-code-v2 {
  &:before {
    content: $one-icon-code-v2;
  }
}
.one-icon-cook-v2 {
  &:before {
    content: $one-icon-cook-v2;
  }
}
.one-icon-copy-v2 {
  &:before {
    content: $one-icon-copy-v2;
  }
}
.one-icon-coupon-02-v2 {
  &:before {
    content: $one-icon-coupon-02-v2;
  }
}
.one-icon-coupon-v2 {
  &:before {
    content: $one-icon-coupon-v2;
  }
}
.one-icon-credit-card-v2 {
  &:before {
    content: $one-icon-credit-card-v2;
  }
}
.one-icon-credit-v2 {
  &:before {
    content: $one-icon-credit-v2;
  }
}
.one-icon-diamond-v2 {
  &:before {
    content: $one-icon-diamond-v2;
  }
}
.one-icon-divide-v2 {
  &:before {
    content: $one-icon-divide-v2;
  }
}
.one-icon-download-box-v2 {
  &:before {
    content: $one-icon-download-box-v2;
  }
}
.one-icon-download-v2 {
  &:before {
    content: $one-icon-download-v2;
  }
}
.one-icon-drop-ticket-v2 {
  &:before {
    content: $one-icon-drop-ticket-v2;
  }
}
.one-icon-ecomerce-v2 {
  &:before {
    content: $one-icon-ecomerce-v2;
  }
}
.one-icon-edit-v2 {
  &:before {
    content: $one-icon-edit-v2;
  }
}
.one-icon-education-v2 {
  &:before {
    content: $one-icon-education-v2;
  }
}
.one-icon-email-v2 {
  &:before {
    content: $one-icon-email-v2;
  }
}
.one-icon-energy-v2 {
  &:before {
    content: $one-icon-energy-v2;
  }
}
.one-icon-facebook-v2 {
  &:before {
    content: $one-icon-facebook-v2;
  }
}
.one-icon-face-id-v2 {
  &:before {
    content: $one-icon-face-id-v2;
  }
}
.one-icon-favorite-v2 {
  &:before {
    content: $one-icon-favorite-v2;
  }
}
.one-icon-filter-v2 {
  &:before {
    content: $one-icon-filter-v2;
  }
}
.one-icon-finger-print-v2 {
  &:before {
    content: $one-icon-finger-print-v2;
  }
}
.one-icon-flash-v2 {
  &:before {
    content: $one-icon-flash-v2;
  }
}
.one-icon-flight-1-v2 {
  &:before {
    content: $one-icon-flight-1-v2;
  }
}
.one-icon-flight-2-v2 {
  &:before {
    content: $one-icon-flight-2-v2;
  }
}
.one-icon-flowers-v2 {
  &:before {
    content: $one-icon-flowers-v2;
  }
}
.one-icon-freeze-v2 {
  &:before {
    content: $one-icon-freeze-v2;
  }
}
.one-icon-gaming-v2 {
  &:before {
    content: $one-icon-gaming-v2;
  }
}
.one-icon-gas-v2 {
  &:before {
    content: $one-icon-gas-v2;
  }
}
.one-icon-general-v2 {
  &:before {
    content: $one-icon-general-v2;
  }
}
.one-icon-gift-v2 {
  &:before {
    content: $one-icon-gift-v2;
  }
}
.one-icon-golden-pass-v2 {
  &:before {
    content: $one-icon-golden-pass-v2;
  }
}
.one-icon-government-v2 {
  &:before {
    content: $one-icon-government-v2;
  }
}
.one-icon-graphic-bars-v2 {
  &:before {
    content: $one-icon-graphic-bars-v2;
  }
}
.one-icon-green-pack-v2 {
  &:before {
    content: $one-icon-green-pack-v2;
  }
}
.one-icon-grin-v2 {
  &:before {
    content: $one-icon-grin-v2;
  }
}
.one-icon-groceries-v2 {
  &:before {
    content: $one-icon-groceries-v2;
  }
}
.one-icon-happy-face-v2 {
  &:before {
    content: $one-icon-happy-face-v2;
  }
}
.one-icon-healt-v2 {
  &:before {
    content: $one-icon-healt-v2;
  }
}
.one-icon-highway-v2 {
  &:before {
    content: $one-icon-highway-v2;
  }
}
.one-icon-home-v2 {
  &:before {
    content: $one-icon-home-v2;
  }
}
.one-icon-hotel-v2 {
  &:before {
    content: $one-icon-hotel-v2;
  }
}
.one-icon-hot-v2 {
  &:before {
    content: $one-icon-hot-v2;
  }
}
.one-icon-hourglass-v2 {
  &:before {
    content: $one-icon-hourglass-v2;
  }
}
.one-icon-house-v2 {
  &:before {
    content: $one-icon-house-v2;
  }
}
.one-icon-id-card-v2 {
  &:before {
    content: $one-icon-id-card-v2;
  }
}
.one-icon-info-v2 {
  &:before {
    content: $one-icon-info-v2;
  }
}
.one-icon-instagram-v2 {
  &:before {
    content: $one-icon-instagram-v2;
  }
}
.one-icon-ipad-v2 {
  &:before {
    content: $one-icon-ipad-v2;
  }
}
.one-icon-isapres-v2 {
  &:before {
    content: $one-icon-isapres-v2;
  }
}
.one-icon-keyboard-v2 {
  &:before {
    content: $one-icon-keyboard-v2;
  }
}
.one-icon-key-v2 {
  &:before {
    content: $one-icon-key-v2;
  }
}
.one-icon-lamp-v2 {
  &:before {
    content: $one-icon-lamp-v2;
  }
}
.one-icon-lantern-v2 {
  &:before {
    content: $one-icon-lantern-v2;
  }
}
.one-icon-lens-v2 {
  &:before {
    content: $one-icon-lens-v2;
  }
}
.one-icon-levels-v2 {
  &:before {
    content: $one-icon-levels-v2;
  }
}
.one-icon-life-buoy-v2 {
  &:before {
    content: $one-icon-life-buoy-v2;
  }
}
.one-icon-light-gas-v2 {
  &:before {
    content: $one-icon-light-gas-v2;
  }
}
.one-icon-light-v2 {
  &:before {
    content: $one-icon-light-v2;
  }
}
.one-icon-linkedin-v2 {
  &:before {
    content: $one-icon-linkedin-v2;
  }
}
.one-icon-location-v2 {
  &:before {
    content: $one-icon-location-v2;
  }
}
.one-icon-log-out-v2 {
  &:before {
    content: $one-icon-log-out-v2;
  }
}
.one-icon-magic-wand-v2 {
  &:before {
    content: $one-icon-magic-wand-v2;
  }
}
.one-icon-message-v2 {
  &:before {
    content: $one-icon-message-v2;
  }
}
.one-icon-missions-v2 {
  &:before {
    content: $one-icon-missions-v2;
  }
}
.one-icon-mobile-v2 {
  &:before {
    content: $one-icon-mobile-v2;
  }
}
.one-icon-money-cash-v2 {
  &:before {
    content: $one-icon-money-cash-v2;
  }
}
.one-icon-money-ico-pe-v2 {
  &:before {
    content: $one-icon-money-ico-pe-v2;
  }
}
.one-icon-money-ico-v2 {
  &:before {
    content: $one-icon-money-ico-v2;
  }
}
.one-icon-more-dots-v2 {
  &:before {
    content: $one-icon-more-dots-v2;
  }
}
.one-icon-more-services-1-v2 {
  &:before {
    content: $one-icon-more-services-1-v2;
  }
}
.one-icon-more-services-v2 {
  &:before {
    content: $one-icon-more-services-v2;
  }
}
.one-icon-mortgage-v2 {
  &:before {
    content: $one-icon-mortgage-v2;
  }
}
.one-icon-motorcycle-v2 {
  &:before {
    content: $one-icon-motorcycle-v2;
  }
}
.one-icon-moustache-card-v2 {
  &:before {
    content: $one-icon-moustache-card-v2;
  }
}
.one-icon-navigation-v2 {
  &:before {
    content: $one-icon-navigation-v2;
  }
}
.one-icon-neutral-face-v2 {
  &:before {
    content: $one-icon-neutral-face-v2;
  }
}
.one-icon-no-card-v2 {
  &:before {
    content: $one-icon-no-card-v2;
  }
}
.one-icon-no-cash-v2 {
  &:before {
    content: $one-icon-no-cash-v2;
  }
}
.one-icon-no-money-v2 {
  &:before {
    content: $one-icon-no-money-v2;
  }
}
.one-icon-notification-v2 {
  &:before {
    content: $one-icon-notification-v2;
  }
}
.one-icon-open-wallet-v2 {
  &:before {
    content: $one-icon-open-wallet-v2;
  }
}
.one-icon-order-again-v2 {
  &:before {
    content: $one-icon-order-again-v2;
  }
}
.one-icon-order-discount-v2 {
  &:before {
    content: $one-icon-order-discount-v2;
  }
}
.one-icon-outsourced-delivery-v2 {
  &:before {
    content: $one-icon-outsourced-delivery-v2;
  }
}
.one-icon-padlock-close-v2 {
  &:before {
    content: $one-icon-padlock-close-v2;
  }
}
.one-icon-padlock-open-v2 {
  &:before {
    content: $one-icon-padlock-open-v2;
  }
}
.one-icon-passport-v2 {
  &:before {
    content: $one-icon-passport-v2;
  }
}
.one-icon-person-card-v2 {
  &:before {
    content: $one-icon-person-card-v2;
  }
}
.one-icon-pets-v2 {
  &:before {
    content: $one-icon-pets-v2;
  }
}
.one-icon-pharmacy-v2 {
  &:before {
    content: $one-icon-pharmacy-v2;
  }
}
.one-icon-phone-02-v2 {
  &:before {
    content: $one-icon-phone-02-v2;
  }
}
.one-icon-phone-down-v2 {
  &:before {
    content: $one-icon-phone-down-v2;
  }
}
.one-icon-phone-signal-v2 {
  &:before {
    content: $one-icon-phone-signal-v2;
  }
}
.one-icon-phone-v2 {
  &:before {
    content: $one-icon-phone-v2;
  }
}
.one-icon-place-holder-v2 {
  &:before {
    content: $one-icon-place-holder-v2;
  }
}
.one-icon-place-v2 {
  &:before {
    content: $one-icon-place-v2;
  }
}
.one-icon-plane-v2 {
  &:before {
    content: $one-icon-plane-v2;
  }
}
.one-icon-points-v2 {
  &:before {
    content: $one-icon-points-v2;
  }
}
.one-icon-prime-v2 {
  &:before {
    content: $one-icon-prime-v2;
  }
}
.one-icon-profile-circle-v2 {
  &:before {
    content: $one-icon-profile-circle-v2;
  }
}
.one-icon-profile-v2 {
  &:before {
    content: $one-icon-profile-v2;
  }
}
.one-icon-promised-payment-v2 {
  &:before {
    content: $one-icon-promised-payment-v2;
  }
}
.one-icon-promoters-v2 {
  &:before {
    content: $one-icon-promoters-v2;
  }
}
.one-icon-promotion-v2 {
  &:before {
    content: $one-icon-promotion-v2;
  }
}
.one-icon-qr-rappi-v2 {
  &:before {
    content: $one-icon-qr-rappi-v2;
  }
}
.one-icon-question-v2 {
  &:before {
    content: $one-icon-question-v2;
  }
}
.one-icon-rappi-card-v2 {
  &:before {
    content: $one-icon-rappi-card-v2;
  }
}
.one-icon-rappi-circle-v2 {
  &:before {
    content: $one-icon-rappi-circle-v2;
  }
}
.one-icon-rappicredits-txt-v2 {
  &:before {
    content: $one-icon-rappicredits-txt-v2;
  }
}
.one-icon-rappicredits-v2 {
  &:before {
    content: $one-icon-rappicredits-v2;
  }
}
.one-icon-rappitendero-v2 {
  &:before {
    content: $one-icon-rappitendero-v2;
  }
}
.one-icon-refund-product-v2 {
  &:before {
    content: $one-icon-refund-product-v2;
  }
}
.one-icon-refund-v2 {
  &:before {
    content: $one-icon-refund-v2;
  }
}
.one-icon-reload-v2 {
  &:before {
    content: $one-icon-reload-v2;
  }
}
.one-icon-restaurant-v2 {
  &:before {
    content: $one-icon-restaurant-v2;
  }
}
.one-icon-rewards-home-v2 {
  &:before {
    content: $one-icon-rewards-home-v2;
  }
}
.one-icon-road-v2 {
  &:before {
    content: $one-icon-road-v2;
  }
}
.one-icon-rocket-v2 {
  &:before {
    content: $one-icon-rocket-v2;
  }
}
.one-icon-rock-v2 {
  &:before {
    content: $one-icon-rock-v2;
  }
}
.one-icon-sad-face-v2 {
  &:before {
    content: $one-icon-sad-face-v2;
  }
}
.one-icon-satellital-tv-v2 {
  &:before {
    content: $one-icon-satellital-tv-v2;
  }
}
.one-icon-search-tab-v2 {
  &:before {
    content: $one-icon-search-tab-v2;
  }
}
.one-icon-settings-v2 {
  &:before {
    content: $one-icon-settings-v2;
  }
}
.one-icon-sexshop-v2 {
  &:before {
    content: $one-icon-sexshop-v2;
  }
}
.one-icon-share1-v2 {
  &:before {
    content: $one-icon-share1-v2;
  }
}
.one-icon-share2-v2 {
  &:before {
    content: $one-icon-share2-v2;
  }
}
.one-icon-share-document-v2 {
  &:before {
    content: $one-icon-share-document-v2;
  }
}
.one-icon-shopping-bag-v2 {
  &:before {
    content: $one-icon-shopping-bag-v2;
  }
}
.one-icon-shopping-v2 {
  &:before {
    content: $one-icon-shopping-v2;
  }
}
.one-icon-shop-v2 {
  &:before {
    content: $one-icon-shop-v2;
  }
}
.one-icon-smile-face-v2 {
  &:before {
    content: $one-icon-smile-face-v2;
  }
}
.one-icon-star-outline-v2 {
  &:before {
    content: $one-icon-star-outline-v2;
  }
}
.one-icon-stationery-v2 {
  &:before {
    content: $one-icon-stationery-v2;
  }
}
.one-icon-street-v2 {
  &:before {
    content: $one-icon-street-v2;
  }
}
.one-icon-strongbox-v2 {
  &:before {
    content: $one-icon-strongbox-v2;
  }
}
.one-icon-support-2-v2 {
  &:before {
    content: $one-icon-support-2-v2;
  }
}
.one-icon-support-v2 {
  &:before {
    content: $one-icon-support-v2;
  }
}
.one-icon-suscriptions-v2 {
  &:before {
    content: $one-icon-suscriptions-v2;
  }
}
.one-icon-sync2-v2 {
  &:before {
    content: $one-icon-sync2-v2;
  }
}
.one-icon-sync-v2 {
  &:before {
    content: $one-icon-sync-v2;
  }
}
.one-icon-table-v2 {
  &:before {
    content: $one-icon-table-v2;
  }
}
.one-icon-taxes-v2 {
  &:before {
    content: $one-icon-taxes-v2;
  }
}
.one-icon-thunder-v2 {
  &:before {
    content: $one-icon-thunder-v2;
  }
}
.one-icon-ticket-v2 {
  &:before {
    content: $one-icon-ticket-v2;
  }
}
.one-icon-tools-v2 {
  &:before {
    content: $one-icon-tools-v2;
  }
}
.one-icon-top-up-pe-v2 {
  &:before {
    content: $one-icon-top-up-pe-v2;
  }
}
.one-icon-top-up-v2 {
  &:before {
    content: $one-icon-top-up-v2;
  }
}
.one-icon-transfer-v2 {
  &:before {
    content: $one-icon-transfer-v2;
  }
}
.one-icon-trash-v2 {
  &:before {
    content: $one-icon-trash-v2;
  }
}
.one-icon-trophy-v2 {
  &:before {
    content: $one-icon-trophy-v2;
  }
}
.one-icon-truck-v2 {
  &:before {
    content: $one-icon-truck-v2;
  }
}
.one-icon-t-shirt-v2 {
  &:before {
    content: $one-icon-t-shirt-v2;
  }
}
.one-icon-tv-v2 {
  &:before {
    content: $one-icon-tv-v2;
  }
}
.one-icon-two-street-v2 {
  &:before {
    content: $one-icon-two-street-v2;
  }
}
.one-icon-visibility-off-v2 {
  &:before {
    content: $one-icon-visibility-off-v2;
  }
}
.one-icon-visibility-v2 {
  &:before {
    content: $one-icon-visibility-v2;
  }
}
.one-icon-wallet-v2 {
  &:before {
    content: $one-icon-wallet-v2;
  }
}
.one-icon-warning2-v2 {
  &:before {
    content: $one-icon-warning2-v2;
  }
}
.one-icon-warning-v2 {
  &:before {
    content: $one-icon-warning-v2;
  }
}
.one-icon-water-v2 {
  &:before {
    content: $one-icon-water-v2;
  }
}
.one-icon-weighing-machine-v2 {
  &:before {
    content: $one-icon-weighing-machine-v2;
  }
}
.one-icon-whatsapp-v2 {
  &:before {
    content: $one-icon-whatsapp-v2;
  }
}
.one-icon-wifi-v2 {
  &:before {
    content: $one-icon-wifi-v2;
  }
}
.one-icon-withdraw-pe-v2 {
  &:before {
    content: $one-icon-withdraw-pe-v2;
  }
}
.one-icon-withdraw-v2 {
  &:before {
    content: $one-icon-withdraw-v2;
  }
}
.one-icon-world-v2 {
  &:before {
    content: $one-icon-world-v2;
  }
}
.one-icon-acces-time-v2 {
  &:before {
    content: $one-icon-acces-time-v2;
  }
}
.one-icon-account-state-v2 {
  &:before {
    content: $one-icon-account-state-v2;
  }
}
.one-icon-add-card-v2 {
  &:before {
    content: $one-icon-add-card-v2;
  }
}
.one-icon-add-location-v2 {
  &:before {
    content: $one-icon-add-location-v2;
  }
}
.one-icon-alarm-on-v2 {
  &:before {
    content: $one-icon-alarm-on-v2;
  }
}
.one-icon-alarm-v2 {
  &:before {
    content: $one-icon-alarm-v2;
  }
}
.one-icon-arrow-back-ios-v2 {
  &:before {
    content: $one-icon-arrow-back-ios-v2;
  }
}
.one-icon-arrow-back-v2 {
  &:before {
    content: $one-icon-arrow-back-v2;
  }
}
.one-icon-arrow-downward-v2 {
  &:before {
    content: $one-icon-arrow-downward-v2;
  }
}
.one-icon-arrow-drop-down-v2 {
  &:before {
    content: $one-icon-arrow-drop-down-v2;
  }
}
.one-icon-arrow-drop-up-v2 {
  &:before {
    content: $one-icon-arrow-drop-up-v2;
  }
}
.one-icon-arrow-forward-ios-v2 {
  &:before {
    content: $one-icon-arrow-forward-ios-v2;
  }
}
.one-icon-arrow-forward-v2 {
  &:before {
    content: $one-icon-arrow-forward-v2;
  }
}
.one-icon-arrow-left-material-v2 {
  &:before {
    content: $one-icon-arrow-left-material-v2;
  }
}
.one-icon-arrow-right-material-v2 {
  &:before {
    content: $one-icon-arrow-right-material-v2;
  }
}
.one-icon-arrow-top-v2 {
  &:before {
    content: $one-icon-arrow-top-v2;
  }
}
.one-icon-arrow-upward-v2 {
  &:before {
    content: $one-icon-arrow-upward-v2;
  }
}
.one-icon-assistant-v2 {
  &:before {
    content: $one-icon-assistant-v2;
  }
}
.one-icon-attach-file-v2 {
  &:before {
    content: $one-icon-attach-file-v2;
  }
}
.one-icon-attachment-v2 {
  &:before {
    content: $one-icon-attachment-v2;
  }
}
.one-icon-attach-money-v2 {
  &:before {
    content: $one-icon-attach-money-v2;
  }
}
.one-icon-autorenew-v2 {
  &:before {
    content: $one-icon-autorenew-v2;
  }
}
.one-icon-backspace-v2 {
  &:before {
    content: $one-icon-backspace-v2;
  }
}
.one-icon-bar-chart-v2 {
  &:before {
    content: $one-icon-bar-chart-v2;
  }
}
.one-icon-block-v2 {
  &:before {
    content: $one-icon-block-v2;
  }
}
.one-icon-bookmark-border-v2 {
  &:before {
    content: $one-icon-bookmark-border-v2;
  }
}
.one-icon-bookmark-v2 {
  &:before {
    content: $one-icon-bookmark-v2;
  }
}
.one-icon-build-v2 {
  &:before {
    content: $one-icon-build-v2;
  }
}
.one-icon-calendar-today-v2 {
  &:before {
    content: $one-icon-calendar-today-v2;
  }
}
.one-icon-call-v2 {
  &:before {
    content: $one-icon-call-v2;
  }
}
.one-icon-camera-alt-v2 {
  &:before {
    content: $one-icon-camera-alt-v2;
  }
}
.one-icon-cancel-v2 {
  &:before {
    content: $one-icon-cancel-v2;
  }
}
.one-icon-chat-v2 {
  &:before {
    content: $one-icon-chat-v2;
  }
}
.one-icon-check-circle-outline-v2 {
  &:before {
    content: $one-icon-check-circle-outline-v2;
  }
}
.one-icon-check-material-v2 {
  &:before {
    content: $one-icon-check-material-v2;
  }
}
.one-icon-cherck-circle-v2 {
  &:before {
    content: $one-icon-cherck-circle-v2;
  }
}
.one-icon-chevron-left-v2 {
  &:before {
    content: $one-icon-chevron-left-v2;
  }
}
.one-icon-chevron-right-v2 {
  &:before {
    content: $one-icon-chevron-right-v2;
  }
}
.one-icon-clock-material-v2 {
  &:before {
    content: $one-icon-clock-material-v2;
  }
}
.one-icon-close-material-v2 {
  &:before {
    content: $one-icon-close-material-v2;
  }
}
.one-icon-colapse-view-v2 {
  &:before {
    content: $one-icon-colapse-view-v2;
  }
}
.one-icon-compare-arrows-v2 {
  &:before {
    content: $one-icon-compare-arrows-v2;
  }
}
.one-icon-computer-v2 {
  &:before {
    content: $one-icon-computer-v2;
  }
}
.one-icon-credit-card-material-v2 {
  &:before {
    content: $one-icon-credit-card-material-v2;
  }
}
.one-icon-delete-outline-v2 {
  &:before {
    content: $one-icon-delete-outline-v2;
  }
}
.one-icon-delete-v2 {
  &:before {
    content: $one-icon-delete-v2;
  }
}
.one-icon-dialpad-v2 {
  &:before {
    content: $one-icon-dialpad-v2;
  }
}
.one-icon-directions-bike-v2 {
  &:before {
    content: $one-icon-directions-bike-v2;
  }
}
.one-icon-directions-bus-v2 {
  &:before {
    content: $one-icon-directions-bus-v2;
  }
}
.one-icon-directions-run-v2 {
  &:before {
    content: $one-icon-directions-run-v2;
  }
}
.one-icon-drive-eta-v2 {
  &:before {
    content: $one-icon-drive-eta-v2;
  }
}
.one-icon-edit-material-v2 {
  &:before {
    content: $one-icon-edit-material-v2;
  }
}
.one-icon-email-material-v2 {
  &:before {
    content: $one-icon-email-material-v2;
  }
}
.one-icon-emoji-events-v2 {
  &:before {
    content: $one-icon-emoji-events-v2;
  }
}
.one-icon-emoji-objects-v2 {
  &:before {
    content: $one-icon-emoji-objects-v2;
  }
}
.one-icon-error-outline-v2 {
  &:before {
    content: $one-icon-error-outline-v2;
  }
}
.one-icon-error-v2 {
  &:before {
    content: $one-icon-error-v2;
  }
}
.one-icon-event-v2 {
  &:before {
    content: $one-icon-event-v2;
  }
}
.one-icon-expand-less-v2 {
  &:before {
    content: $one-icon-expand-less-v2;
  }
}
.one-icon-expand-more-v2 {
  &:before {
    content: $one-icon-expand-more-v2;
  }
}
.one-icon-expand-view-v2 {
  &:before {
    content: $one-icon-expand-view-v2;
  }
}
.one-icon-explore-v2 {
  &:before {
    content: $one-icon-explore-v2;
  }
}
.one-icon-favorite-border-v2 {
  &:before {
    content: $one-icon-favorite-border-v2;
  }
}
.one-icon-favorite-material-v2 {
  &:before {
    content: $one-icon-favorite-material-v2;
  }
}
.one-icon-filter-material-v2 {
  &:before {
    content: $one-icon-filter-material-v2;
  }
}
.one-icon-fingerprint-v2 {
  &:before {
    content: $one-icon-fingerprint-v2;
  }
}
.one-icon-flight-v2 {
  &:before {
    content: $one-icon-flight-v2;
  }
}
.one-icon-get-app-v2 {
  &:before {
    content: $one-icon-get-app-v2;
  }
}
.one-icon-gift-material-v2 {
  &:before {
    content: $one-icon-gift-material-v2;
  }
}
.one-icon-gluten-v2 {
  &:before {
    content: $one-icon-gluten-v2;
  }
}
.one-icon-headset-mic-v2 {
  &:before {
    content: $one-icon-headset-mic-v2;
  }
}
.one-icon-help-outline-v2 {
  &:before {
    content: $one-icon-help-outline-v2;
  }
}
.one-icon-help-v2 {
  &:before {
    content: $one-icon-help-v2;
  }
}
.one-icon-highlight-off-v2 {
  &:before {
    content: $one-icon-highlight-off-v2;
  }
}
.one-icon-highlight-v2 {
  &:before {
    content: $one-icon-highlight-v2;
  }
}
.one-icon-history-v2 {
  &:before {
    content: $one-icon-history-v2;
  }
}
.one-icon-home-material-v2 {
  &:before {
    content: $one-icon-home-material-v2;
  }
}
.one-icon-id-card-material-v2 {
  &:before {
    content: $one-icon-id-card-material-v2;
  }
}
.one-icon-image-v2 {
  &:before {
    content: $one-icon-image-v2;
  }
}
.one-icon-inbox-v2 {
  &:before {
    content: $one-icon-inbox-v2;
  }
}
.one-icon-info-material-v2 {
  &:before {
    content: $one-icon-info-material-v2;
  }
}
.one-icon-iwatch-v2 {
  &:before {
    content: $one-icon-iwatch-v2;
  }
}
.one-icon-keyboard-material-v2 {
  &:before {
    content: $one-icon-keyboard-material-v2;
  }
}
.one-icon-label-v2 {
  &:before {
    content: $one-icon-label-v2;
  }
}
.one-icon-language-v2 {
  &:before {
    content: $one-icon-language-v2;
  }
}
.one-icon-lifebuoy-material-v2 {
  &:before {
    content: $one-icon-lifebuoy-material-v2;
  }
}
.one-icon-lifemiles-v2 {
  &:before {
    content: $one-icon-lifemiles-v2;
  }
}
.one-icon-linkedin-v21 {
  &:before {
    content: $one-icon-linkedin-v21;
  }
}
.one-icon-link-v2 {
  &:before {
    content: $one-icon-link-v2;
  }
}
.one-icon-local-activity-v2 {
  &:before {
    content: $one-icon-local-activity-v2;
  }
}
.one-icon-local-bar-v2 {
  &:before {
    content: $one-icon-local-bar-v2;
  }
}
.one-icon-local-florist-v2 {
  &:before {
    content: $one-icon-local-florist-v2;
  }
}
.one-icon-local-gas-station-v2 {
  &:before {
    content: $one-icon-local-gas-station-v2;
  }
}
.one-icon-local-grocery-store-v2 {
  &:before {
    content: $one-icon-local-grocery-store-v2;
  }
}
.one-icon-local-hotel-v2 {
  &:before {
    content: $one-icon-local-hotel-v2;
  }
}
.one-icon-local-mall-v2 {
  &:before {
    content: $one-icon-local-mall-v2;
  }
}
.one-icon-local-offer-v2 {
  &:before {
    content: $one-icon-local-offer-v2;
  }
}
.one-icon-local-pizza-v2 {
  &:before {
    content: $one-icon-local-pizza-v2;
  }
}
.one-icon-local-printshop-v2 {
  &:before {
    content: $one-icon-local-printshop-v2;
  }
}
.one-icon-lock-close-material-v2 {
  &:before {
    content: $one-icon-lock-close-material-v2;
  }
}
.one-icon-lock-open-material-v2 {
  &:before {
    content: $one-icon-lock-open-material-v2;
  }
}
.one-icon-loop-v2 {
  &:before {
    content: $one-icon-loop-v2;
  }
}
.one-icon-loyalty-v2 {
  &:before {
    content: $one-icon-loyalty-v2;
  }
}
.one-icon-map-v2 {
  &:before {
    content: $one-icon-map-v2;
  }
}
.one-icon-medical-prescription-v2 {
  &:before {
    content: $one-icon-medical-prescription-v2;
  }
}
.one-icon-menu-book-v2 {
  &:before {
    content: $one-icon-menu-book-v2;
  }
}
.one-icon-menu-v2 {
  &:before {
    content: $one-icon-menu-v2;
  }
}
.one-icon-mic-none-v2 {
  &:before {
    content: $one-icon-mic-none-v2;
  }
}
.one-icon-mic-off-v2 {
  &:before {
    content: $one-icon-mic-off-v2;
  }
}
.one-icon-mic-v2 {
  &:before {
    content: $one-icon-mic-v2;
  }
}
.one-icon-monetization-v2 {
  &:before {
    content: $one-icon-monetization-v2;
  }
}
.one-icon-money-circle-v2 {
  &:before {
    content: $one-icon-money-circle-v2;
  }
}
.one-icon-more-horiz-v2 {
  &:before {
    content: $one-icon-more-horiz-v2;
  }
}
.one-icon-more-vert-v2 {
  &:before {
    content: $one-icon-more-vert-v2;
  }
}
.one-icon-my-location-v2 {
  &:before {
    content: $one-icon-my-location-v2;
  }
}
.one-icon-navigation-material-v2 {
  &:before {
    content: $one-icon-navigation-material-v2;
  }
}
.one-icon-near-me-v2 {
  &:before {
    content: $one-icon-near-me-v2;
  }
}
.one-icon-new-releases-v2 {
  &:before {
    content: $one-icon-new-releases-v2;
  }
}
.one-icon-north-east-v2 {
  &:before {
    content: $one-icon-north-east-v2;
  }
}
.one-icon-notifications-active-v2 {
  &:before {
    content: $one-icon-notifications-active-v2;
  }
}
.one-icon-not-interested-v2 {
  &:before {
    content: $one-icon-not-interested-v2;
  }
}
.one-icon-offline-bolt-v2 {
  &:before {
    content: $one-icon-offline-bolt-v2;
  }
}
.one-icon-pause-circle-v2 {
  &:before {
    content: $one-icon-pause-circle-v2;
  }
}
.one-icon-people-v2 {
  &:before {
    content: $one-icon-people-v2;
  }
}
.one-icon-person-outline-v2 {
  &:before {
    content: $one-icon-person-outline-v2;
  }
}
.one-icon-person-pin-v2 {
  &:before {
    content: $one-icon-person-pin-v2;
  }
}
.one-icon-person-v2 {
  &:before {
    content: $one-icon-person-v2;
  }
}
.one-icon-phone-iphone-v2 {
  &:before {
    content: $one-icon-phone-iphone-v2;
  }
}
.one-icon-pin-v2 {
  &:before {
    content: $one-icon-pin-v2;
  }
}
.one-icon-play-circle-v2 {
  &:before {
    content: $one-icon-play-circle-v2;
  }
}
.one-icon-promo-material-v2 {
  &:before {
    content: $one-icon-promo-material-v2;
  }
}
.one-icon-qr-rappi-material-v2 {
  &:before {
    content: $one-icon-qr-rappi-material-v2;
  }
}
.one-icon-query-builder-v2 {
  &:before {
    content: $one-icon-query-builder-v2;
  }
}
.one-icon-rappi-moustache-v2 {
  &:before {
    content: $one-icon-rappi-moustache-v2;
  }
}
.one-icon-refresh-v2 {
  &:before {
    content: $one-icon-refresh-v2;
  }
}
.one-icon-remove-circle-outline-v2 {
  &:before {
    content: $one-icon-remove-circle-outline-v2;
  }
}
.one-icon-remove-circle-v2 {
  &:before {
    content: $one-icon-remove-circle-v2;
  }
}
.one-icon-remove-v2 {
  &:before {
    content: $one-icon-remove-v2;
  }
}
.one-icon-report-v2 {
  &:before {
    content: $one-icon-report-v2;
  }
}
.one-icon-restaurant-menu-v2 {
  &:before {
    content: $one-icon-restaurant-menu-v2;
  }
}
.one-icon-room-service-v2 {
  &:before {
    content: $one-icon-room-service-v2;
  }
}
.one-icon-room-v2 {
  &:before {
    content: $one-icon-room-v2;
  }
}
.one-icon-save-v2 {
  &:before {
    content: $one-icon-save-v2;
  }
}
.one-icon-search-v2 {
  &:before {
    content: $one-icon-search-v2;
  }
}
.one-icon-send-v2 {
  &:before {
    content: $one-icon-send-v2;
  }
}
.one-icon-sentimental-satisfied-v2 {
  &:before {
    content: $one-icon-sentimental-satisfied-v2;
  }
}
.one-icon-sentiment-dissatisfied-v2 {
  &:before {
    content: $one-icon-sentiment-dissatisfied-v2;
  }
}
.one-icon-sentiment-very-dissatisfied-v2 {
  &:before {
    content: $one-icon-sentiment-very-dissatisfied-v2;
  }
}
.one-icon-sentiment-very-satisfied-v2 {
  &:before {
    content: $one-icon-sentiment-very-satisfied-v2;
  }
}
.one-icon-share-material-v2 {
  &:before {
    content: $one-icon-share-material-v2;
  }
}
.one-icon-shopping-basket-v2 {
  &:before {
    content: $one-icon-shopping-basket-v2;
  }
}
.one-icon-shopping-cart-v2 {
  &:before {
    content: $one-icon-shopping-cart-v2;
  }
}
.one-icon-sports-esports-v2 {
  &:before {
    content: $one-icon-sports-esports-v2;
  }
}
.one-icon-stamp-v2 {
  &:before {
    content: $one-icon-stamp-v2;
  }
}
.one-icon-star-border-v2 {
  &:before {
    content: $one-icon-star-border-v2;
  }
}
.one-icon-star-half-v2 {
  &:before {
    content: $one-icon-star-half-v2;
  }
}
.one-icon-stars-v2 {
  &:before {
    content: $one-icon-stars-v2;
  }
}
.one-icon-star-v2 {
  &:before {
    content: $one-icon-star-v2;
  }
}
.one-icon-store-v2 {
  &:before {
    content: $one-icon-store-v2;
  }
}
.one-icon-supervised-user-circle-v2 {
  &:before {
    content: $one-icon-supervised-user-circle-v2;
  }
}
.one-icon-swap-horizontal-circle-v2 {
  &:before {
    content: $one-icon-swap-horizontal-circle-v2;
  }
}
.one-icon-swap-horiz-v2 {
  &:before {
    content: $one-icon-swap-horiz-v2;
  }
}
.one-icon-swap-vertical-circle-v2 {
  &:before {
    content: $one-icon-swap-vertical-circle-v2;
  }
}
.one-icon-swap-vert-v2 {
  &:before {
    content: $one-icon-swap-vert-v2;
  }
}
.one-icon-sync-alt-v2 {
  &:before {
    content: $one-icon-sync-alt-v2;
  }
}
.one-icon-sync-disabled-v2 {
  &:before {
    content: $one-icon-sync-disabled-v2;
  }
}
.one-icon-sync-material-v2 {
  &:before {
    content: $one-icon-sync-material-v2;
  }
}
.one-icon-theaters-v2 {
  &:before {
    content: $one-icon-theaters-v2;
  }
}
.one-icon-thumb-down-v2 {
  &:before {
    content: $one-icon-thumb-down-v2;
  }
}
.one-icon-thumb-up-v2 {
  &:before {
    content: $one-icon-thumb-up-v2;
  }
}
.one-icon-thunder-v21 {
  &:before {
    content: $one-icon-thunder-v21;
  }
}
.one-icon-timelapse-v2 {
  &:before {
    content: $one-icon-timelapse-v2;
  }
}
.one-icon-timer-v2 {
  &:before {
    content: $one-icon-timer-v2;
  }
}
.one-icon-trending-up-v2 {
  &:before {
    content: $one-icon-trending-up-v2;
  }
}
.one-icon-tv-material-v2 {
  &:before {
    content: $one-icon-tv-material-v2;
  }
}
.one-icon-update-v2 {
  &:before {
    content: $one-icon-update-v2;
  }
}
.one-icon-vegan-v2 {
  &:before {
    content: $one-icon-vegan-v2;
  }
}
.one-icon-vegetarian-v2 {
  &:before {
    content: $one-icon-vegetarian-v2;
  }
}
.one-icon-visibility-material-v2 {
  &:before {
    content: $one-icon-visibility-material-v2;
  }
}
.one-icon-visibility-off-material-v2 {
  &:before {
    content: $one-icon-visibility-off-material-v2;
  }
}
.one-icon-volumen-off-v2 {
  &:before {
    content: $one-icon-volumen-off-v2;
  }
}
.one-icon-volumen-up-v2 {
  &:before {
    content: $one-icon-volumen-up-v2;
  }
}
.one-icon-vpn-key-v2 {
  &:before {
    content: $one-icon-vpn-key-v2;
  }
}
.one-icon-walnuts-v2 {
  &:before {
    content: $one-icon-walnuts-v2;
  }
}
.one-icon-watch-later-v2 {
  &:before {
    content: $one-icon-watch-later-v2;
  }
}
.one-icon-watch-v2 {
  &:before {
    content: $one-icon-watch-v2;
  }
}
.one-icon-work-outline-v2 {
  &:before {
    content: $one-icon-work-outline-v2;
  }
}
.one-icon-work-v2 {
  &:before {
    content: $one-icon-work-v2;
  }
}
.one-icon-account-balance-v2 {
  &:before {
    content: $one-icon-account-balance-v2;
  }
}
.one-icon-account-balance-wallet-v2 {
  &:before {
    content: $one-icon-account-balance-wallet-v2;
  }
}
.one-icon-account-box-v2 {
  &:before {
    content: $one-icon-account-box-v2;
  }
}
.one-icon-account-circle-v2 {
  &:before {
    content: $one-icon-account-circle-v2;
  }
}
.one-icon-add-box-v2 {
  &:before {
    content: $one-icon-add-box-v2;
  }
}
.one-icon-add-circle-outline-v2 {
  &:before {
    content: $one-icon-add-circle-outline-v2;
  }
}
.one-icon-add-circle-v2 {
  &:before {
    content: $one-icon-add-circle-v2;
  }
}
.one-icon-add-material-v2 {
  &:before {
    content: $one-icon-add-material-v2;
  }
}
.one-icon-airplanemode-active-v2 {
  &:before {
    content: $one-icon-airplanemode-active-v2;
  }
}
.one-icon-outline-menu {
  &:before {
    content: $one-icon-outline-menu;
  }
}
.one-icon-outline-notification-v2 {
  &:before {
    content: $one-icon-outline-notification-v2;
  }
}
.one-icon-outline-activity {
  &:before {
    content: $one-icon-outline-activity;
  }
}
.one-icon-outline-happy-face {
  &:before {
    content: $one-icon-outline-happy-face;
  }
}
.one-icon-outline-document-check {
  &:before {
    content: $one-icon-outline-document-check;
  }
}
.one-icon-outline-increase {
  &:before {
    content: $one-icon-outline-increase;
  }
}
.one-icon-document-alert {
  &:before {
    content: $one-icon-document-alert;
  }
}
.one-icon-hyperlink {
  &:before {
    content: $one-icon-hyperlink;
  }
}
.one-icon-accessibility {
  &:before {
    content: $one-icon-accessibility;
  }
}
.one-icon-add-card {
  &:before {
    content: $one-icon-add-card;
  }
}
.one-icon-alpha {
  &:before {
    content: $one-icon-alpha;
  }
}
.one-icon-apartment {
  &:before {
    content: $one-icon-apartment;
  }
}
.one-icon-backup {
  &:before {
    content: $one-icon-backup;
  }
}
.one-icon-bill {
  &:before {
    content: $one-icon-bill;
  }
}
.one-icon-change-pin-v3 {
  &:before {
    content: $one-icon-change-pin-v3;
  }
}
.one-icon-calendar-money {
  &:before {
    content: $one-icon-calendar-money;
  }
}
.one-icon-cc-amparada {
  &:before {
    content: $one-icon-cc-amparada;
  }
}
.one-icon-check-double {
  &:before {
    content: $one-icon-check-double;
  }
}
.one-icon-check-square {
  &:before {
    content: $one-icon-check-square;
  }
}
.one-icon-close-eyes {
  &:before {
    content: $one-icon-close-eyes;
  }
}
.one-icon-close-square {
  &:before {
    content: $one-icon-close-square;
  }
}
.one-icon-cloud {
  &:before {
    content: $one-icon-cloud;
  }
}
.one-icon-color {
  &:before {
    content: $one-icon-color;
  }
}
.one-icon-components {
  &:before {
    content: $one-icon-components;
  }
}
.one-icon-inactive {
  &:before {
    content: $one-icon-inactive;
  }
}
.one-icon-contactless {
  &:before {
    content: $one-icon-contactless;
  }
}
.one-icon-copyright-v3 {
  &:before {
    content: $one-icon-copyright-v3;
  }
}
.one-icon-credit-card-v3 {
  &:before {
    content: $one-icon-credit-card-v3;
  }
}
.one-icon-crystal-ball-ai {
  &:before {
    content: $one-icon-crystal-ball-ai;
  }
}
.one-icon-cut {
  &:before {
    content: $one-icon-cut;
  }
}
.one-icon-verified-user-v2 {
  &:before {
    content: $one-icon-verified-user-v2;
  }
}
.one-icon-danger {
  &:before {
    content: $one-icon-danger;
  }
}
.one-icon-dashboard {
  &:before {
    content: $one-icon-dashboard;
  }
}
