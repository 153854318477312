.title-bar-container {
  flex-flow: row wrap;
  flex: 1 1 100%;

  .breadcrumbs-wrapper {
    flex: 1 1 100%;
    min-height: 16px;
    margin-top: 10px;
  }

  .title-wrapper {
    flex: 1 1 100%;
    flex-direction: row;
    display: flex;

    .title-text {
      flex: 1 1 100%;
      max-width: 70%;
      font-size: 40px;
      color: $primary-text;
      margin: 0 10px 24px 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action {
      flex: 1 1 100%;
      max-width: 30%;
      margin: -5px 0 10px;
      font-size: 14px;

      .download,
      .report,
      .link {
        cursor: pointer;
        width: auto;
        height: 32px;
        border-radius: 8px;
        margin-left: auto;

        .btn-wrapper {
          border-radius: 8px;
          padding: 1px 12px 1px 0;
          margin-left: auto;
          height: 100%;
          width: fit-content;
          background-color: $primary-background-dark;
        }

        .btn-text {
          line-height: 1.7;
          font-weight: bold;
          color: white;
          display: inline-flex;
          padding: 0 4px 2px 0;
        }

        svg {
          display: inline-flex;
          width: 22px;
          height: 28px;
          margin: 2px 6px 0 6px;
          fill: white;
          vertical-align: bottom;
        }
      }

      .link {
        background-color: transparent;

        .btn-wrapper {
          background-color: $primary;
        }

        svg {
          width: 20px;
        }
      }
    }
  }
}
